import React from 'react';
import Popup from "reactjs-popup";
import { Container,
         Header,
         Button,
         Icon } from 'semantic-ui-react';
import useForm from "react-hook-form";

export default ( { trigger, addUser, showCategory, catInfo }, props ) => {
  const { handleSubmit, register, errors } = useForm(),
        onSubmit = (data, e, close) => {addUser(data, e, close)};
  return (
    <Popup
      modal
      closeOnDocumentClick
      lockScroll
      trigger={
        trigger
      }
      position="right center"
    >
      { close => (
          <Container textAlign='left'>
            <form
              onSubmit={
                handleSubmit((data, e) => onSubmit(data, e, close))
              }
              className="modal"
            >
              <span className="close" onClick={close}>
                <Icon name="close" />
              </span>
              <Header>
                Adaugă utilizator
              </Header>
              <Header className="modal__top" dividing>
                { catInfo &&
                  <div className="modal__top__title">
                    <span>
                      ID utilizator:
                    </span>
                    <span>
                      { catInfo._id }
                    </span>
                  </div>
                }
                <input
                  className='hidden'
                  name='category_id'
                  defaultValue={ catInfo && catInfo._id }
                  ref={register({
                    required: '',
                  })}
                />
              </Header>
              <Header as="h5">
                <span className="modal__title">
                  Login:
                </span>
                <input
                  className='input-ui'
                  defaultValue={ catInfo && catInfo.login ? catInfo.login : '' }
                  name="login"
                  placeholder={ catInfo && catInfo.login ? catInfo.login : 'Login' }
                  ref={register({
                    required: 'Required',
                  })}
                />
                <div className='errors'>
                  { errors.login && errors.login.message }
                </div>
              </Header>
              <Header as="h5">
                  <span className="modal__title">
                    Parola:
                  </span>
              </Header>
              <input
                className='input-ui'
                defaultValue={ catInfo && catInfo.password ? catInfo.password : '' }
                name="password"
                placeholder={ catInfo && catInfo.password ? catInfo.password : 'Parola' }
                ref={register({
                  required: 'Required',
                })}
              />
              <div className='errors'>
                { errors.password && errors.password.message }
              </div>
              <div className="modal__bottom">
                  <Button className="button__green" floated='right'>
                    Salvează
                  </Button>
              </div>
            </form>
          </Container>
      )}
    </Popup>
  )
}
