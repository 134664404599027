import React, { useState } from 'react'
import Popup from "reactjs-popup";
import ModalImg from './ModalImg'
import { Container,
         Header,
         Button,
         Icon,
         Dropdown,
         Checkbox } from 'semantic-ui-react';
import useForm from "react-hook-form";

export default ( { trigger, addCategory, showCategory, catInfo, fiteredParentCategories, dataCategory, validator, key }, props ) => {
    const [inputValue, changeValue] = useState(catInfo ? catInfo.type : 1),
          [parentCategory, parentChange] = useState(catInfo ? catInfo.parent_id : ''),
          [inputValueRedirect, changeInputValueRedirect] = useState(catInfo ? catInfo.is_redirection : 1),
          showThisCategory = () => showCategory( catInfo._id, catInfo.sw_active ),
          options = fiteredParentCategories,
          { handleSubmit, register, errors } = useForm(),
          onSubmit = (data, e, close) => { addCategory(data, e, close) };
    return (
        <Popup  modal
                closeOnDocumentClick
                lockScroll
                trigger={
                    trigger
                }
                position="right center">
                    { (close) => (
                        <Container textAlign='left'>
                            <form onSubmit={
                                    handleSubmit(( data, e ) => onSubmit(data, e, close))
                                }
                                className="modal">
                                <span className="close" onClick={close}>
                                    <Icon name="close" />
                                </span>
                                <Header>
                                    Categorii
                                </Header>
                                <Header className="modal__top" dividing>
                                    { catInfo &&
                                        <div className="modal__top__title">
                                            <span>Category ID: </span>
                                            { catInfo && <span> {catInfo._id} </span> }
                                        </div>
                                    }
                                    <input className='hidden' name='category_id' defaultValue={ catInfo ? catInfo._id : 0 } ref={register({
                                        required: false,
                                    })} />
                                    { catInfo && <div> <Icon name={`eye${catInfo && !catInfo.sw_active ? ' slash' : ''}`} onClick={ showThisCategory }  /> </div> }
                                </Header>
                                <Header as="h5">
                                    <span className="modal__title">Categorie părinte</span> <span className='errors'>*</span>
                                    <Dropdown placeholder='Categorie' options={options} defaultValue={parentCategory} selected onChange={ (e, {value}) => { parentChange(value)} } name="parent" fluid selection />
                                    <input className='hidden' defaultValue={parentCategory} name='parent_id'
                                        ref={register({
                                            required: false,
                                        })} />
                                </Header>
                                <Header as="h5">
                                    <span className="modal__title">Titlu RU:</span>
                                </Header>

                                <input className='input-ui' defaultValue={ catInfo && catInfo.title ? catInfo.title.ru : '' } name="title_ru" placeholder={ catInfo && catInfo.title ? catInfo.title.ru : 'Titlu RU' }
                                    ref={register({
                                        required: 'Cîmp obligatoriu',
                                        minLength: 1,
                                        pattern: {
                                          message: "invalid Title RO:"
                                        }
                                    })}
                                    />
                                <div className='errors'>{errors.title_ru && errors.title_ru.message}</div>
                                <Header as="h5">
                                    <span className="modal__title">Titlu RO:</span>
                                </Header>
                                <input className='input-ui' defaultValue={ catInfo && catInfo.title ? catInfo.title.ro : '' } name="title_ro" placeholder={ catInfo && catInfo.title ? catInfo.title.ro : 'Titlu RO' }
                                    ref={register({
                                      required: 'Cîmp obligatoriu',
                                      minLength: 1,
                                      pattern: {
                                        message: "invalid Title RO:"
                                      }
                                    })}
                                    />
                                <div className='errors'>{errors.title_ro && errors.title_ro.message}</div>

                                <div className="modal__image" style={{position: 'relative'}}>
                                    <ModalImg iconed={catInfo && catInfo.icon} required={true} />
                                </div>
                                <div className="modal__bottom">
                                    <Checkbox floated="left" label='Categorie finală' default={ inputValue } onClick={ () => changeValue( inputValue === 2 ? 1 : 2 )} defaultChecked={ catInfo && catInfo.type === 2 } />
                                    <input type="hidden" name="type" value={inputValue}
                                        ref={register({
                                          required: false,
                                        })}
                                        />
                                      <Checkbox floated="left" label='Redirecționare' default={ inputValueRedirect } onClick={ () => changeInputValueRedirect( inputValueRedirect === 2 ? 1 : 2 )} defaultChecked={ catInfo && catInfo.is_redirection === true } />
                                    <input type="hidden" name="is_redirection" value={inputValueRedirect}
                                        ref={register({
                                          required: false,
                                        })}
                                        />
                                    <Button className="button__green" floated='right'>Salvează</Button>
                                </div>
                            </form>
                        </Container>
                )}
        </Popup>
    )
}
