import React from 'react'
import { NavLink } from 'react-router-dom'
import { Table, Icon } from 'semantic-ui-react';
import { Draggable } from "react-beautiful-dnd";
import ConfirmPopup from '../ConfirmPopup';

export default function PagesTemplate( { dataPages, deletePage, showPage, editPage, selectedCategory, getDataPopup, index } ) {
    const { _id, title, sw_active } = dataPages;
    const deleteThisPage = () => deletePage( _id );
    return (
        <Draggable key={index} draggableId={dataPages._id} index={index} >
                  {(provided, snapshot) => (
                    <tr
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                        <Table.Cell width={1}>
                            { _id }
                        </Table.Cell>
                        <Table.Cell>
                            { title && title.ro }
                        </Table.Cell>
                        <Table.Cell>
                            { title && title.ru }
                        </Table.Cell>
                        <Table.Cell width={3}>
                            <div className='category__edit'>
                                <Icon name={`eye${!sw_active ? ' slash' : ''}`} onClick={ showPage } />
                                <NavLink className='edit__link' to={`/cabinet/page/edit/${_id}`} ><Icon name='pencil' /></NavLink>

                                <ConfirmPopup
                                    actionToDo={ deleteThisPage }
                                    trigger={
                                        <Icon name='delete' />
                                    }
                                    modal
                                    textToTranslate={'pagina'}
                                    closeOnDocumentClick
                                />
                            </div>
                        </Table.Cell>
                    </tr>
                  )}
            </Draggable>
    )
}
