import React from 'react'
import Popup from "reactjs-popup";
import Moment from 'react-moment';

import {Header,
        Icon,
        Table} from 'semantic-ui-react';

export default ( { trigger, dataRedirect }, props ) => {
    const data = dataRedirect;
    return (
        <Popup  modal
                closeOnDocumentClick
                lockScroll
                trigger={
                    trigger
                }
                position="right center"
                >
                    { close => (
                        <div className="modal">
                            <span className="close" onClick={close}>
                              <Icon name="close" />
                            </span>
                            <Header>
                                Informatie
                            </Header>
                            <Table celled padded centered={"true"}>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>
                                            Numele:
                                        </Table.Cell>
                                        <Table.Cell>
                                            {data.name_surname ? data.name_surname : '---'}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            Virsta:
                                        </Table.Cell>
                                        <Table.Cell>
                                            {data.age ? data.age : '---'}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            Gender:
                                        </Table.Cell>
                                        <Table.Cell>
                                            {data.gender ? data.gender : '---'}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            Data:
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Moment unix format="DD.MM.YYYY">
                                                {data.date ? data.date : '---'}
                                            </Moment>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            Denumirea organizației:
                                        </Table.Cell>
                                        <Table.Cell>
                                            {data.map_point_title ? data.map_point_title['ro'] : '---' }
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            Numele angajatului:
                                        </Table.Cell>
                                        <Table.Cell>
                                            {data.employee_name_surname ? data.employee_name_surname : '---'}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            Telefonul angajatului:
                                        </Table.Cell>
                                        <Table.Cell>
                                            {data.phone ? data.phone : '---'}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            Numărul legitimației:
                                        </Table.Cell>
                                        <Table.Cell>
                                            {data.badge_number ? data.badge_number : '---'}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            Subdiviziune:
                                        </Table.Cell>
                                        <Table.Cell>
                                            {data.subdivision ? data.subdivision : '---'}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            Comentarii:
                                        </Table.Cell>
                                        <Table.Cell>
                                            {data.comment ? data.comment: '---'}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                )}
        </Popup>
    )
}
