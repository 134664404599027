import React from 'react';

const FooterCabinet = props => (
    <footer>
        <div className="footer ui container">
            <div className="hf0o36-12 kqoSFP">
              <a className="is-999" href="http://www.positivepeople.md/" rel="noopener noreferrer" target="_blank">iniziativapozitiva</a>
              <a className="is-998" href="http://www.positivepeople.md/" rel="noopener noreferrer" target="_blank">iniziativapozitiva</a>
              <div className="hf0o36-13 ghxHgI">
                  <a className="is-simpals" href="https://simpals.md" rel="noopener noreferrer" target="_blank">simpals</a>
              </div>
              <a className="is-ip" href="https://www.md.undp.org/" rel="noopener noreferrer" target="_blank">igp</a>
              <a className="is-mama" href="http://politia.md/" rel="noopener noreferrer" target="_blank">igp</a>
              <a className="is-price" href="https://www.unodc.org/" rel="noopener noreferrer" target="_blank">unodc</a>
            </div>
        </div>
    </footer>
);

export default FooterCabinet;
