import React, { Component } from 'react';
import 'react-dates/initialize';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Rules from './pages/Rules';
import Cabinet from './pages/Cabinet';
import NotFoundComponent from './components/NotFoundComponent';
import NoAccess from './components/NoAccess';
import {URI} from './config';
import i18n from "i18next";
import { withTranslation, initReactI18next} from "react-i18next";
import 'react-app-polyfill/ie11';
import { CookiesProvider, withCookies } from 'react-cookie';

import ro from './translation/ro.json';
import ru from './translation/ru.json';


let Lang = document.cookie.match(new RegExp('(^| )i18next=([^;]+)')) ?
    document.cookie.match(new RegExp('(^| )i18next=([^;]+)'))[2]
    :
    ['ro','ru'].some( key => key === window.location.pathname.split('/')[1] )?
        window.location.pathname.split('/')[1]
        : 'ro'
i18n
  .use(initReactI18next)
  .init({
      lng: Lang,
      resources: { ru, ro }
  })
class App extends Component {
    state = {
        data: null
    }
    componentDidMount() {
        let href = window.location.href,
            hrefCheck = ~href.indexOf('?auth_info');
        if (hrefCheck) {
            let token = href.slice(~hrefCheck + 11);
            let option = {
                method: 'POST',
                body: JSON.stringify( { token } ),
            }
            fetch( URI + '/user/generate_auth_token', option )
                .then(res => res.json()).then(result => {
                    let { data } = result;
                    if (data.length) {
                        let href = window.location.href,
                            hrefCheck = ~href.indexOf('?auth_info'),
                            token = href.slice(~hrefCheck + 11);
                        document.cookie = `auth=${token}`;
                        window.history.replaceState( null, '', window.location.origin )
                        this.setState({data : JSON.parse(data)})
                    }
                })
        } else if (sessionStorage.getItem('newdata')) {
            this.setState({data: JSON.parse(sessionStorage.getItem('newdata'))})
        }
        return null
    }
    render() {
        const { t } = this.props;
        return (
            <>
                <CookiesProvider>
                    <Router>
                        <div className="wrapper">
                            <Switch>
                                <Route exact path='/' render={() => <Home data={this.state.data} LangApp={Lang} t={t} />} />
                                <Route exact path='/rules' render={() => <Rules LangApp={Lang} t={t} />} />
                                { this.props.cookies.cookies.auth && this.props.cookies.cookies.auth.length > 10 ?
                                    <Route path='/cabinet' render={() => <Cabinet data={this.state.data} LangApp={Lang} t={t} />} />
                                    :
                                    <Route component={NoAccess} />
                                }
                                <Route component={NotFoundComponent} />
                            </Switch>
                        </div>
                    </Router>
                </CookiesProvider>
            </>
        );
    }
}

export default withTranslation()(withCookies(App));
