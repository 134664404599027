import React, { Component } from 'react';
import Headercabinet from '../components/Headercabinet.js';
import Footercabinet from '../components/Footercabinet.js';
import Pages from './Pages.js';
import Category from './Category.js';
import Points from './Points.js';
import Users from './Users.js';
import Chestionare from './Chestionare.js';
import UsersApp from './UsersApp.js';
import Redirected from './Redirect.js';
import Emails from './Emails.js';
import PagesAdd from './PagesAdd.js';
import PointsAdd from './PointsAdd.js';
import ChestionareAdd from './ChestionareAdd.js';
import { Container } from 'semantic-ui-react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

export default class Cabinet extends Component {
    render() {
        const { data,t, LangApp } = this.props;
        return (
            <BrowserRouter >
            <>
                <Container className="margin">
                <Headercabinet data={data} className="container" />
                    <div className="switch-cabinet">
                        <Switch>
                            <Route path='/cabinet/category' render={(e) => <Category {...e} data={data} t={t} />} />
                            <Route path='/cabinet/page/add' render={(e) => <PagesAdd {...e} data={data} t={t} /> } />
                            <Route path='/cabinet/page/edit/:id' render={(e) => <PagesAdd {...e} t={t} /> } />
                            <Route path='/cabinet/page' render={() => <Pages data={data} t={t} /> } />
                            <Route path='/cabinet/points/add' render={(e) => <PointsAdd {...e} t={t} /> } />
                            <Route path='/cabinet/points/edit/:id' render={(e) => <PointsAdd {...e} t={t} /> } />
                            <Route path='/cabinet/points' render={() => <Points data={data} t={t} /> } />
                            <Route path='/cabinet/chestionare/add' render={(e) => <ChestionareAdd data={data} {...e} t={t} /> } />
                            <Route path='/cabinet/chestionare/edit/:id' render={(e) => <ChestionareAdd data={data} {...e} t={t} /> } />
                            <Route path='/cabinet/chestionare' render={() => <Chestionare data={data} t={t} /> } />
                            <Route path='/cabinet/user' render={() => <Users data={data} t={t} /> } />
                            <Route path='/cabinet/app-user' render={() => <UsersApp data={data} t={t} /> } />
                            <Route path='/cabinet/redirectionari' render={() => <Redirected data={data} t={t} lang={LangApp}/> } />
                            <Route path='/cabinet/emails' render={() => <Emails data={data} t={t} lang={LangApp}/> } />
                            <Route render={() => <Base t={t}/> } />
                        </Switch>
                    </div>
                </Container>
                <Footercabinet />
            </>
            </BrowserRouter>
        );
    }
}
const Base = (props) => {
    return(
        <div style={{textAlign: 'center', marginTop: '10vw',fontSize:'30px'}}>
            Pagina in dezvoltare
        </div>
    )
}
