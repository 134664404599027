import React, { useState } from 'react'
import logo from '../css/img.png'

const handleChange = (event, changeFile) => {
  changeFile( URL.createObjectURL(event.target.files[0]) )
}

export default ({catInfoIcon}) => {
    const [ file, changeFile ] = useState( catInfoIcon || null)
    return (
        <>

            { <img alt="empty-page" src={  file || logo } /> }
            <label className="modal__image_button">
                Adaugă imagine
                <input type="file" name="cover_img" accept='.jpg, .png, .jpeg, .svg' style={{ display : 'none' }}
                       onChange={ (e) => handleChange(e, changeFile) }
                       />
            </label>
        </>
    );
}
