import React from 'react';
import { NavLink } from 'react-router-dom';
import ChestionareTemplate from './templates/ChestionareTemplate';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Table,
         Container,
         Header,
         Button} from 'semantic-ui-react';
import { URI } from '../config';

export default class Chestionare extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            ready : false,
            result : []
        }
        this.updatePages = this.updatePages.bind(this)
    }
    updatePages(){
        const page = 1
        fetch(URI + `/questionnaire/list-view/?page=${page}`, {
            method : 'GET',
            credentials: "same-origin",
        })
        .then(res => res.json())
        .then( ({data, errors}) => this.setState({
            ready : true,
            result : {
                data,
                errors
            }
        }))
        .catch(e => this.setState({ ready : true, result : { errors : {error : e.message } }}));
    }
    componentDidMount() {
        this.updatePages()
    }
    render() {
        const { ready, result } = this.state
        if(!ready)
            return (
                <div className="ui active centered inline loader"></div>
            )
        if( result &&  Object.keys(result.errors).length )
            return(
                <div>
                    500 - Something went wrong
                </div>
            )
        return (
            <>
                <Container>
                    <Header as='h2' floated='left'>Chestionare</Header>
                    <NavLink to='/cabinet/chestionare/add'><Button floated='right' className="category__add">Adaugă formular</Button></NavLink>
                </Container>
                <Table celled padded centered={"true"}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>ID:</Table.HeaderCell>
                            <Table.HeaderCell>Nume chestionar:</Table.HeaderCell>
                            <Table.HeaderCell>Teste susținute:</Table.HeaderCell>
                            <Table.HeaderCell>Punctaj mediu acumulat:</Table.HeaderCell>
                            <Table.HeaderCell>Editare:</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable-1" index={0}>
                                {(provided, snapshot) => (
                                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                        { result && result.data.map( (dataChestionar, index) => {
                                            let showChestionarFunction = this.showChestionar.bind( this, index )
                                            let deleteChestionarFunction = this.deleteChestionar.bind(this, index)
                                                return <ChestionareTemplate index={index} key={ dataChestionar._id } updatePages={this.updatePages} dataChestionar={ dataChestionar } addCategory={ this.addCategory } showChestionar={ showChestionarFunction } deleteChestionar={ deleteChestionarFunction } />
                                            }) }
                                        {provided.placeholder}
                                    </tbody>
                                )}
                          </Droppable>
                    </DragDropContext>
                </Table>
            </>
        )
    }
    showChestionar = ( index, id, sw_active ) => fetch( URI + '/questionnaire/view', { method: 'PUT', credentials: "same-origin", body: JSON.stringify( { id, action : sw_active ? 'hide' : 'show' } )  } )
                                                 .then( ressult => ressult.json() )
                                                 .then( json => this.setState(prevState => this.updatePages()));
    deleteChestionar = ( index, id ) => fetch( URI + '/questionnaire/view', { method: 'DELETE', credentials: "same-origin", body: JSON.stringify( { id } ) } )
                                        .then( ressult => ressult.json() )
                                        .then( json => this.setState(prevState => this.updatePages()) );
    addCategory = async (e) => {
        e.preventDefault()
        const submitData = new FormData(e.currentTarget),
              json = JSON.stringify({
            'name': submitData.get('name'),
            'surname': submitData.get('surname'),
            idnp: submitData.get('idnp'),
            email: submitData.get('email'),
            type: parseInt(submitData.get('type'))
        });
        await fetch( URI + '/user/view', { method: 'POST', credentials: "same-origin", body: json } )
        this.setState(prevState => this.updatePages())
    }
}
