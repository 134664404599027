import React, {useState} from 'react'
import {Icon} from 'semantic-ui-react';
import CategoryPopup from '../CategoryPopup'
import ConfirmPopup from '../ConfirmPopup';
import Subcategory from './Subcategory';
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";

export default function CategoryTemplate( { dataCategory, deleteCategory, showCategory, addCategory, index, parentCategories, fiteredParentCategories, onDragEnd } ){

    const { _id, title, sw_active } = dataCategory,
          showThisCategory = () => showCategory( _id, sw_active ),
          deleteThisCategory = () => deleteCategory( _id ),
          [opened, changeOpened] = useState('false');
    return (
      <>
        <Draggable key={index} draggableId={dataCategory._id} index={index}>
                  {(provided, snapshot) => (
                  <>
                      <div className={dataCategory.children.length ? `table-tr with-child data-${dataCategory._id}` : `table-tr`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                          <div style={{position: 'relative'}}>
                              { dataCategory && dataCategory.children.length ? (
                                <Icon
                                  style={{
                                    height: '41px',
                                    width: '35px',
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                  }}
                                  name={`${opened ? 'plus' : 'minus'}`}
                                  onClick={ () => changeOpened(!opened) }
                                />
                              ) : (
                              null)}
                              <span style={{marginLeft: '25px'}}>{ _id }</span>
                          </div>
                          <div>
                            { title && title.ro }
                          </div>
                          <div>
                            { title && title.ru }
                          </div>
                          <div>
                              <div className='category__edit'>
                                  <Icon name={`eye${!sw_active ? ' slash' : ''}`} onClick={ showThisCategory } />

                                  <CategoryPopup
                                      trigger = { <Icon name='pencil' /> }
                                      addCategory={addCategory}
                                      fiteredParentCategories={fiteredParentCategories}
                                      showCategory={showThisCategory}
                                      catInfo={ dataCategory }
                                  />

                                  <ConfirmPopup
                                    actionToDo={ deleteThisCategory }
                                    trigger={
                                      <Icon name='delete' />
                                    }
                                    modal
                                    textToTranslate={'categoria'}
                                    closeOnDocumentClick
                                  />
                              </div>
                          </div>
                      </div>
                    </>
                  )}
        </Draggable>
        <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={dataCategory._id} index={0} type="SUBCATEGORY">
                    {(provided, snapshot) => (
                        <div className={ opened ? `table-child table-closed` : `table-child table-opened` }
                            ref={provided.innerRef} type="SUBCATEGORY">
                            {
                                dataCategory.children && dataCategory.children.map((dataSubCategory, index) => (
                                    <Subcategory
                                        dataCategory={ dataSubCategory }
                                        index={index}
                                        key={ dataSubCategory._id }
                                        deleteCategory={ deleteCategory }
                                        fiteredParentCategories={fiteredParentCategories}
                                        addCategory={addCategory}
                                        showCategory={showCategory}
                                    />
                                ))
                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
        </DragDropContext>
      </>
    )
}
