import React, {Component} from 'react';
import { Menu, Image, List } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import logo from '../images/logo.png';

export default class MenuExampleNameProp extends Component {

    constructor(props) {
    super(props);
        this.state = {
          articles: [],
          displayLogout: false,
        };
    }

render() {
    const { activeItem } = this.state,
          { data } = this.props
    return (
        <Menu className="header-cabinet">
            <Menu.Item name='Categorii' active={activeItem === 'categorii'} onClick={this.handleItemClick} as={NavLink} to='/cabinet/category'>
                Categorii
            </Menu.Item>
            <Menu.Item name='Pagini' active={activeItem === 'pagini'} onClick={this.handleItemClick} as={NavLink} to="/cabinet/page/" >
                Pagini
            </Menu.Item>
            <Menu.Item name='Map points' active={activeItem === 'Map points'} onClick={this.handleItemClick} as={NavLink} to="/cabinet/points" >
                Locații
            </Menu.Item>
            <Menu.Item name='Chestionare' active={activeItem === 'Chestionare'} onClick={this.handleItemClick} as={NavLink} to="/cabinet/chestionare" >
                Chestionare
            </Menu.Item>
            <Menu.Item name='Users' active={activeItem === 'Users'} onClick={this.handleItemClick} as={NavLink} to="/cabinet/user" >
                Administratori
            </Menu.Item>
            <Menu.Item name='Users' active={activeItem === 'Users'} onClick={this.handleItemClick} as={NavLink} to="/cabinet/app-user" >
                Utilizatorii aplicației
            </Menu.Item>
            <Menu.Item name='Redirecționari' active={activeItem === 'Redirecționari'} onClick={this.handleItemClick} as={NavLink} to="/cabinet/redirectionari" >
                Redirecționari
            </Menu.Item>
            <Menu.Item name='Emailuri' active={activeItem === 'Emailuri'} onClick={this.handleItemClick} as={NavLink} to="/cabinet/emails" >
                Raport despre traume
            </Menu.Item>
            <Menu.Item className="right aligned profile-info">
              <Image avatar src={logo} />
              <List.Content>
                <List.Header>
                    {data &&  data.user_data.FirstName }
                </List.Header>
                    {data &&  data.user_data.LastName }
              </List.Content>
            </Menu.Item>
            <Menu.Item name='Iesire' active={activeItem === 'Iesire'} onClick={this.logout} >
                Ieșire
            </Menu.Item>
        </Menu>
    )
  }
  logout = e => {
      e.preventDefault()
    //   const {data} = this.props;
            // formData = new FormData()
            // formData.append('idnp',data.name_id)
            // formData.append('session_id',data.session_index)
            // formData.append('token',data.token)
            // formData.append('sp_request_id',data.sp_request_id)
            // fetch(URI + '/user/mpass/logout/url/', {
            //     method : 'POST',
            //     body : new URLSearchParams(formData)
            // }).then(res => res.json() ).then(res => {
            sessionStorage.clear()
            document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
            window.location.href = '/'
        // })
  }
}
