import React from 'react';
import { Container, Grid } from 'semantic-ui-react';

import Header from '../components/header';
import Footer from '../components/footer';

import { URI } from '../config';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import { withCookies } from 'react-cookie';

const redirect = () => {
    return (
        fetch(URI + '/user/mpass/login/url', { method: 'POST' })
            .then(res => res.json())
            .then(result => {
                if (Object.keys(result.errors).length) {
                    return(
                        alert('Wrong data!')
                    )
                }
                return (
                    window.location.href = result.data.url
                )
            })
        )
    }

class Rules extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            loader : false
        }
        this.ShowLoad = this.ShowLoad.bind(this)
    }
    render() {
        const { t } = this.props;
        return (
            <>
            <Container>
                <Grid >
                        <div className={`loader ${ !this.state.loader? 'hidden' : 'getFixed'}`}></div>
                        <Header t={t} ShowLoad={this.ShowLoad} />
                        <div className={`wrapper-home ${ this.state.loader && 'unvisible'}`}>
                            <div className="wrapper__home">
                                <img src={logo} style={{maxHeight: '140px'}} alt="logo_id" />

                            </div>
                            <div className="">
                                <h1 style={{marginBottom: 30+'px', marginTop: 30+'px', textAlign: 'center'}}>
                                    Правила ипользования приложения и безопасность.
                                </h1>
                                <div>

                                    <h3>Концепция мобильного приложения</h3>
                                    <p>
                                        Инновационность внедряемого, уникального в своём роде инструмента заключается в интеграции в мобильные телефоны сотрудников полиции мобильного приложения с конкретными инструкциями к действиям в работе с людьми из уязвимых категорий по вопросам ВИЧ, ТБ и наркопотребления, что обеспечит повышение уровня защищённости сотрудников полиции, а также эффективности и качества помощи, основанной на инструкциях рекомендаций к алгоритмам действий своевременной и достоверной информации, потребностях и правах людей из уязвимых групп.
                                    </p>
                                    <h3>Функциональные возможности</h3>
                                    <p>
                                        Данное мобильное приложение будет предусматривать возможность для обновления информации включая изменение рекомендуемых алгоритмов действий, расширение категорий уязвимых групп.
                                    </p>
                                    <p>
                                        Мобильное приложение призвано усилить возможности Генерального Инспектората Полиции по принятию основанных на качественной информации решений в рамках взаимодействия с людьми, живущими, затронутыми, либо уязвимыми к ВИЧ, ТБ и наркопотребления.
                                    </p>
                                    <p>
                                        Мобильное приложение позволяет в реальном времени с мобильного устройства получить конкретные инструкции к действиям в работе с людьми, живущими, затронутыми, либо уязвимыми к ВИЧ и ТБ.
                                    </p>
                                    <p>
                                        А также мобильное приложение позволяет в реальном времени отправить отчёт о случаях травмы с риском инфицирования ВИЧ и осуществить переадресацию представителей уязвимых к ВИЧ и ТБ групп к услугам неправительственных организаций.
                                    </p>
                                    <p>
                                        Благодаря внедрению в работу полиции мобильного приложения будет достигнут прогресс в осуществлении эффективных подходов в оказании помощи и работы с людьми из уязвимых категорий.
                                    </p>
                                    <h3>Алгоритм использования мобильного приложения.</h3>
                                    <p>
                                        В рамках своей деятельности, сотрудник полиции взаимодействует с различными людьми включая уязвимые категории. Идентификация представителя целевой группы, в зависимости от принадлежности к уязвимой гуппе происходит на основании следующих критериев:
                                    </p>
                                    <ul style={{marginLeft: 20+'px', marginBottom: 20+'px'}}>
                                        <li> Выраженные симптомы (ТБ, наркопотребление.)</li>
                                        <li> Поведенческие особенности (наркопотребление);</li>
                                        <li> Раскрытие статуса в рамках коммуникации (ВИЧ, ТБ, Наркопотребление).</li>
                                    </ul>

                                    <p>
                                        После идентификации профиля представителя целевой аудитории, сотрудник полиции входит в приложение и в соответствии с профилем и актуальной ситуацией получает инструкции, разработанные специалистами, например:
                                    </p>
                                    <ul style={{marginLeft: 20+'px', marginBottom: 20+'px'}}>
                                        <li> Оказание помощи в случае передозировки наркотиками;</li>
                                        <li> Как защитить себя? Пути передачи ВИЧ, ТБ;</li>
                                        <li> Проведение обыска (ВИЧ, ТБ)</li>
                                        <li> Ближайшие специализированные сервисы для людей из уязвимых категорий;</li>
                                        <li> И другое.</li>
                                    </ul>
                                    <h3>Аутентификация и контроль доступа</h3>
                                    <p>
                                        Для администрирования и использования мобильного приложения осуществленна синхронизация с правительственной электронной услугой аутентификации и контроля доступа (MPass)
                                    </p>
                                    <p>
                                        Общие условия. Правительственная электронная услуга аутентификации и контроля доступа (MPass) – многоразовая услуга, предоставляемая на уровне общей технологической платформы Правительства, целью которой является обеспечение интегрирующего, безопасного и гибкого механизма аутентификации и контроля доступа пользователей в информационные системы, включая электронные услуги. Функция аутентификации пользователей может осуществляться различными способами аутентификации, предоставленными поставщиками аутентификации.
                                    </p>
                                    <p>
                                        Порядок функционирования и администрирования правительственной электронной услуги аутентификации и контроля доступа (MPass) устанавливает Положение о правительственной электронной услуге аутентификации и контроля доступа (MPass), утвержденное Постановлением Правительства №1090 от 31 декабря 2013 года.
                                    </p>
                                    <p>
                                        Пользователь использует услугу MPass для регистрации, аутентификации и авторизации в контексте использования электронных услуг.
                                    </p>
                                    <p>
                                        Аутентификация и авторизация осуществляются посредством поставщиков идентификационной информации и поставщиков утверждений, интегрированных в услугу MPass. Ответственность за правильную аутентификацию идентичности пользователя возлагается на поставщиков идентификационной информации, интегрированных в MPass.
                                    </p>
                                    <p>
                                        Безопасность. Услуга MPass размещена на общей правительственной платформе MCloud и подчиняется требованиям безопасности, предусмотренным действующим законодательством. Обмен сообщениями с информационными системами в целях функциональности услуги MPass осуществляется через защищенные каналы, с использованием механизмов криптографической защиты информации. Процессы создания, использования и проверки подлинности цифровой подписи в рамках услуги MPass осуществляются в соответствии с действующим законодательством в области цифровой подписи.
                                    </p>
                                    <h3>Обработка персональных данных пользователей мобильного приложения.</h3>
                                    <p>
                                        В соответсвии с законодальством Республики Молдова, для использования мобильного приложения, пользователю необходимо дать своё Согласие на обработку персональных данных и Обязательство о неразглашении персональных данных.
                                    </p>
                                    <h3>Модель согласия на обработку персональных данных.</h3>
                                    <p>
                                        <i>
                                        Настоящим я (далее по тексту настоящего согласия упоминается также как Пользователь), в соответствии с положениями ст. 1, 5, 6 Закона № 133 от 08.07.2011 г. о персональных данных, свободно, своей волей предоставляю согласие на любые способы обработки СВОИХ персональных ДаННЫХ В данном приложении, включая любое действие (операцию) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с моими персональными данными, в том числе сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, блокирование, удаление персональных данных.
                                        </i>
                                    </p>
                                    <p>
                                        <i>Под персональными данными понимается любая информация, относящаяся прямо или косвенно к Пользователю, в частности, но, не ограничиваясь, фамилия, имя, отчество, возраст, телефон, электронная почта, регион и город проживания, паспортные данные, должность и профессиональные навыки (далее — Персональные данные).</i>
                                    </p>
                                    <p>
                                        <i>Согласие на обработку моих персональных данных, дается до истечения сроков хранения соответствующей информации или документов, содержащих указанную выше информацию, определяемых в соответствии с действующим законодательством Республики Молдова.</i>
                                    </p>
                                    <p>
                                        <i>В соответствии с положениями ст.16 Закона № 133 от 08.07.2011 о персональных данных, я проинформирован/а о праве возражать против обработки персональных данных касающихся меня, посредством направления письменного уведомления.</i>
                                    </p>
                                    <p><i>[ ] Да, я согласен с обработкой персональных данных</i></p>

                                    <h3>Модель Обязательства о неразглашении персональных данных</h3>
                                    <p>
                                        <i>Я проинформирован/а что в соответствии с положениями Закона №. 133 от 08.07.2011 о защите персональных данных, я обязан/а обеспечивать конфиденциальности персональных данных которые обрабатываю и риски связанных с их обработкой, и обязуюсь полностью соблюдать правила использования персональных данных. Я понимаю, что нарушение положений о защите персональных данных влечет за собой дисциплинарную, материальную и/или уголовную ответственность.</i>
                                    </p>

                                    <p>
                                        <i>[ ] Да, я беру на себя обязательство сохранять конфиденциальность и безопасность обрабатываемых персональных данных</i>
                                    </p>
                                </div>
                            </div>

                        </div>
                    </Grid>
                </Container>
                <Footer />
            </>
        );
    }
    ShowLoad = () => this.setState({ loader : true }, () => setTimeout( () => this.setState({ loader : false }), 600))
}

export default withCookies(Rules);
