import React from "react";
import Popup from "reactjs-popup";
import {Icon} from 'semantic-ui-react';
import styled from "styled-components";

const popUpStyle = {
  borderRadius: '10px',
  padding: '15px 10px 0 10px',
  width: '40%',
  textAlign: 'center'
}

const SimpleDiv = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-family: Helvetica;
  margin-bottom: 15px;
  line-height: 30px;
  margin-top: 20px;
  color: black;
`;

export default ({trigger, actionToDo, textToTranslate}) => (
  <Popup
    trigger={
      trigger
    }
    contentStyle={popUpStyle}
    modal
  >
    {close => (
      <div className="modal">
        <span className="close" onClick={close}>
          <Icon name='delete' />
        </span>
        <SimpleDiv className="content">
          Sunteți sigur că doriți să ștergeți {textToTranslate}?
        </SimpleDiv>
        <button
          className="button ui blue"
          style={{marginRight: '20px'}}
          onClick={() => {
            actionToDo()
            close();
          }}
        >
          Șterge
        </button>
        <button
          className="button ui"
          onClick={() => {
            close();
          }}
        >
          Închide
        </button>
      </div>
    )}
  </Popup>
);
