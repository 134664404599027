import React, {useState} from 'react'
import {Field} from 'formik'
import { Header,
         Button,
         Icon,
         Input,
         Form} from 'semantic-ui-react';
import {URI} from '../../config.js'

export default function Questions({question, activeQuestion, setData, setFieldValue, isEditPage, pathname, questionarId, props, index, isActive, changeActive, sendQuestionFunction, options, addAnswerEditFunction, handleClickAnswer, values, editedIndex}) {
    const  {_id, title} = question,
           [isEditable, changeEdit] = useState(false),
           [isEditableAnswer, changeEditAnswer] = useState(false),
           [testFetch, setTestFetch] = useState(true),
           [testUp, setTestUp] = useState(question.sw_active),
           [answerId, changeAnswerId] = useState(''),
           changeEditQuestion = () => {
               if(isEditable){
                 const title = values.questions[index].title;
                 const answers = values.questions[index].answers[0] ? values.questions[index].answers[0].value : ''
                 const idQuestionEdited = values.questions[index]._id
                 sendQuestionFunction(idQuestionEdited, title, answers)
               }
               return (
                 changeEdit(!isEditable)
               )
           },
           changeEditAnswerFunction = () => {
               if(isEditableAnswer){
                 // handle to save
                 const intedPoints = values.answers.map(point => {
                   return {
                     title: point.title,
                     point: parseInt(point.points),
                   }
                 })
                 handleClickAnswer(_id, intedPoints, answerId)
               }
               fetch(URI + `/answer/list-view?questionnaire_id=${questionarId}&question_id=${_id}&id=${_id}`, {method: 'GET', credentials: "same-origin"})
                .then(res => res.json())
                .then(res => {
                  /// заполнение даныыми ответов (answers - initialValues Formik)
                  console.log(res)
                  if (res.data.length) {
                    let questionId = res.data[0]._id;
                    let typeFromData = res.data[0].type;
                    let responseData = res.data[0].value;
                    let answerId = res.data[0]._id;
                    return (
                      setFieldValue(`answers`, responseData),
                      changeAnswerId(questionId),
                      setFieldValue(`answerType`, typeFromData),
                      setFieldValue(`answerId`, answerId)
                    )
                  }
                  else {
                    let questionId = _id;
                    let responseData = [{
                      title: {
                        ro: '',
                        ru: '',
                      },
                      points: 10,
                    }];
                    return (
                      setFieldValue(`answers`, responseData),
                      changeAnswerId(questionId),
                      setFieldValue(`answerId`, answerId)
                    )
                  }
                })
               return changeEditAnswer(!isEditableAnswer)
           },
           // addNewAnswer = () => {
           //   const oldAnswers = values.answers;
           //   const newAnswer = {
           //     points: 10,
           //     title: {
           //       ru: '',
           //       ro: ''
           //     }
           //   }
           //   oldAnswers.push(newAnswer)
           // },
           showQuestion = (id, sw_active, index) => fetch(URI + `/question/view?id=${_id}`, {method: 'PUT', credentials: "same-origin", body: JSON.stringify({'id': _id, action: testUp? 'hide' : 'show'})})
                                                  .then(res => res.json())
                                                  .then(
                                                     () => setTestUp(!testUp)
                                                  ),
           deleteQuestion = (id, index) => fetch(URI + `/question/view?id=${_id}`, {method: 'DELETE', credentials: "same-origin", body: JSON.stringify({'id': _id})})
                                       .then(res => res.json())
                                       .then( res => { document.location.reload() }),
           deleteAnswer = () => fetch(URI + `/answer/view?id=${answerId}`, {method: 'DELETE', credentials: "same-origin", body: JSON.stringify({'id': answerId})})
                                       .then(res => res.json())
                                       .then( res => { document.location.reload() }),
           showThisQuestion = () =>  {
             return (
               changeActive( _id ),
               setFieldValue(`questionId`, _id)
             )
           }
    // console.log(questionarId, _id, question)

    if(!question.answers && isEditPage && testFetch){
      fetch(URI + `/answer/list-view?questionnaire_id=${questionarId}&question_id=${_id}&id=${_id}`, {method: 'GET', credentials: "same-origin"})
          .then(res => res.json())
          .then(res => {
              let newArray =  values.questions;
              const lengthAnswers = res.data.length - 1
              newArray.splice(editedIndex, 1, {...question, answers: res.data, answersLength: lengthAnswers})
              setFieldValue(`questions`, newArray)
          })
          .catch(e => console.log(e.message))
          setTestFetch(false)
    }
    if(isActive) {
        return (
        <>
          <div className='preform'
            style={{
             marginTop:'10px',
             backgroundColor: 'white',
             padding: '45px',
             border: '1px solid gainsboro',
             borderRadius: '5px'}}
          >
           <Header as='h3'>
               Editează întrebarea
           </Header>
           {
             ['ro','ru'].map(lang => (
               <Form.Group key={lang} widths='equal'>
                 <div className='field'>
                   <label>
                     Întrebare {lang.toUpperCase()}:
                   </label>
                   <div className='ui input'>
                     <Field
                       id={`form-input-question-${lang}`}
                       name={`questions.${index}.title.${lang}`}
                       placeholder={title[lang] || `Ce inseamna HIV?`}
                       key={lang}
                       readOnly={!isEditable}
                       disabled={!isEditable}
                      />
                   </div>
                 </div>
               </Form.Group>
             ))
           }
           <Button
             type='button'
             floated='right'
             color={!isEditable? 'grey' : 'green'}
             onClick={changeEditQuestion}
            >
             {!isEditable ? 'Editează' : 'Salvează și continuă'}
           </Button>
          </div>

            <div className='preform'
              style={{
                marginTop:'65px',
                backgroundColor: 'white',
                padding: '45px',
                border: '1px solid gainsboro',
                borderRadius: '5px'}}
             >
            <div style={{marginTop:'20px'}}>
              <Header as='h3'>
                Editează răspuns
              </Header>
              { values.questions[index] && values.questions[index].answers ?
                <Form.Group className="edit__label" widths='equal' style={{position: 'relative'}}>
                    <span className='errors errors__chestionnare'>*</span>
                    <Form.Select
                      fluid
                      label='Tip'
                      options={options}
                      name='value'
                      placeholder='Tip'
                      disabled
                      defaultValue={ values.questions[index].answers[0] ? values.questions[index].answers[0].type : ''}
                    />
                </Form.Group>
                : (
                  <div>
                    Nu aveti intrebari
                  </div>
                )
              }
              {/* end checkbox edit - type */}

              {/* start answer values edit - input field */}
              { values.questions[index].answers[0] ? values.questions[index].answers[0].value.map((answer, indexAnswer) => {
                return (
                  <Form.Group className="answer" key={indexAnswer} widths='equal'>
                    <div className='field'>
                      {['ro','ru'].map(lang => (
                        <Form.Group key={indexAnswer + lang} widths='equal'>
                         <div className='field'>
                           <label>
                             Răspuns {lang.toUpperCase()}:
                           </label>
                           <div className='ui input'>
                             <Field
                               id={`form-input-question-answer-${lang}`}
                               name={`answers.${indexAnswer}.title.${lang}`}
                               defaultValue={answer.title[lang]}
                               placeholder={answer.title[lang] || `Ce inseamna HIV?`}
                               disabled={!isEditableAnswer}
                              />
                           </div>
                         </div>
                       </Form.Group>
                      ))}
                      <label>
                          Punctaj:
                      </label>
                      <div className='ui input'>
                        <Field
                          name={`answers.${indexAnswer}.points`}
                          placeholder={answer.points}
                          defaultValue={answer.points}
                          disabled={!isEditableAnswer}
                         />
                      </div>
                    </div>
                  </Form.Group>
                )}) : ( values.answers.map((answer, index) => {
                  return (
                    <>
                      {['ro','ru'].map(lang => (
                            <Form.Group widths='equal' key={lang}>
                                <div className='field'>
                                    <label>
                                        Răspuns {lang.toUpperCase()}: <span className='errors'>*</span>
                                    </label>
                                    <div className='ui input'>
                                        <Field
                                            id={`form-input-question-answer-${lang}`}
                                            name={`answers.${index}.title.${lang}`}
                                            control={Input}
                                            placeholder={values.title[lang] && `HIV inseamna ...`}
                                            key={lang}
                                        />
                                    </div>
                                </div>
                            </Form.Group>
                      ))}
                      <Form.Group widths='equal' key={index}>
                          <div className='field'>
                              <label>
                                  Punctaj:
                              </label>
                              <div className='ui input'>
                                  <Field
                                      defaultValue={ '10' }
                                      name={`answers.${index}.points`}
                                      control={Input}
                                      type="number"
                                  />
                              </div>
                          </div>
                      </Form.Group>
                    </>
                  )})
                )}
                {/*{ isEditableAnswer &&
                    <>
                        <Form.Button
                          // className='button__green'
                          onClick={addAnswerEditFunction}
                          color='blue'
                          disabled={!isEditableAnswer}
                          // floated='left'
                        >
                          Adaugă raspuns
                        </Form.Button>
                    </>
                } */}
                <Form.Button
                    floated="left"
                    color={'green'}
                    onClick={deleteAnswer}
                    disabled={!isEditableAnswer}
                    >
                    {'Ștergeți răspunsurile'}
                </Form.Button>

                  <Form.Button
                    className='button__green answer__button'
                    onClick={changeEditAnswerFunction}
                    color={!isEditableAnswer? 'grey' : 'green'}
                    floated='right'
                  >
                   { !isEditableAnswer ?
                       'Editează'
                   :
                      'Salvează și continuă' }
                  </Form.Button>
              </div>
            </div>
          </>
        )
    }
    return (
        <div className='result-questions'>
            <div>
                { _id }
            </div>
            <div>
                { title && title.ro }
            </div>
            <div>
                <div className='category__edit'>
                    <Icon name={`eye${!testUp ? ' slash' : ''}`} onClick={showQuestion} />
                    <Icon name='pencil' onClick={showThisQuestion} />
                    <Icon name='delete' onClick={deleteQuestion} value={index}/>
                </div>
            </div>
        </div>
    )
}
