import React from 'react';
import { Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import Header from '../components/header';
import Footer from '../components/footer';

import logo from '../images/logo.png';

const footerStyle = {
	position: 'absolute',
	bottom: '0',
	left: '0',
	right: '0'
}

const linkButton = {
	border: '1px solid #00000026',
	borderRadius: '20px',
	padding: '7px 20px',
	marginTop: '70px'
}

function NoAccess() {
	const { t } = useTranslation();
	return (
		<>
			<Grid >
				<Header t={t} />
				<div className="wrapper__home">
					<div className="wrapper__home">
						<a href="/">
							<img
								src={logo}
								style={{maxHeight: '140px'}}
								alt="logo_id"
							/>
						</a>
						<h2 className="wrapper__home__title__bottom">
							Nu aveti access pentru pagina data.
						</h2>
						<a style={linkButton} href="/">
							Pagina principala
						</a>
					</div>
				</div>
			</Grid>
			<Footer style={footerStyle} />
		</>
	)
}

export default NoAccess;