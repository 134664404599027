import React, { useState } from 'react'
import Popup from "reactjs-popup";
import {Container,
        Header,
        Button,
        Icon,
        Input,
        Checkbox} from 'semantic-ui-react';
import useForm from "react-hook-form";

export default ( { trigger, addUser, showCategory, catInfo }, props ) => {
    const [ inputValue, changeValue ] = useState(catInfo && catInfo.type),
          { handleSubmit, register, errors } = useForm(),
          onSubmit = (data, e, close) => {  addUser(data, e, close) };
    return (
        <Popup  modal
                closeOnDocumentClick
                lockScroll
                trigger={
                    trigger
                }
                position="right center">
                    { close => (
                        <Container textAlign='left'>
                            <form
                                onSubmit={
                                    handleSubmit((data, e) => onSubmit(data, e, close))
                                }
                                className="modal">
                                <span className="close" onClick={close}>
                                    <Icon name="close" />
                                </span>
                                <Header>
                                    <input className='hidden' name='category_id' defaultValue={ catInfo && catInfo._id}
                                      ref={register({})}
                                      />
                                    Adaugă utilizator
                                </Header>
                                <Header className="modal__top" dividing>
                                    { catInfo &&
                                    <div className="modal__top__title">
                                        <span>ID utilizator: </span>
                                            <span> {catInfo._id} </span>
                                        </div>
                                    }
                                </Header>
                                <Header as="h5">
                                    <span className="modal__title">Numele:</span>
                                    <Input defaultValue={ catInfo ? catInfo.name : ''} fluid name="name" placeholder={ catInfo ? catInfo.name : 'Dumitru' } />
                                </Header>
                                <Header as="h5">
                                    <span className="modal__title">Prenumele:</span>
                                </Header>
                                <Input defaultValue={ catInfo ? catInfo.surname : ''} fluid name="surname" placeholder={ catInfo ? catInfo.surname : 'Rosca' } />
                                <Header as="h5">
                                    <span className="modal__title">IDNP<span className='necesar'>*</span>:</span>
                                </Header>
                                <input className='input-ui' defaultValue={ catInfo ? catInfo.idnp : '' } name="idnp" placeholder={ catInfo ? catInfo.idnp : '13 cifre IDNP *' }
                                    ref={register({
                                      required: 'Required',
                                      pattern: {
                                        value: /^[0-9._%+-]{13}$/i,
                                        message: "invalid idnp code, must have 13 keys"
                                      }
                                    })}
                                    />
                                <div className='errors'>{errors.idnp && errors.idnp.message}</div>
                                <Header as="h5">
                                    <span className="modal__title">Email:</span>
                                </Header>
                                <Input fluid defaultValue={ catInfo ? catInfo.email : '' } name="email" placeholder={ catInfo ? catInfo.email : 'test@mail.ru' }
                                    ref={register({
                                      required: 'Required',
                                      pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "invalid email address"
                                      }
                                    })}
                                    />
                                <div className='errors'>{errors.email && errors.email.message}</div>
                                <Header as="h5">
                                    <span className="modal__title">Tip acces:</span>
                                </Header>
                                <div>
                                    <Checkbox floated="left" label='Administrator' value={ inputValue } onClick={ () => changeValue( inputValue === 2 ? 1 : 2 )} defaultChecked={ catInfo && catInfo.type === 2  } />
                                    <input type="hidden" name="type" value={inputValue || 2} />
                                </div>
                                <div className="modal__bottom">
                                    <Button className="button__green" floated='right'>Salvează</Button>
                                </div>
                            </form>
                        </Container>
                )}
        </Popup>
    )
}
