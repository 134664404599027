import React from 'react';
import { NavLink } from 'react-router-dom';
import { Table,
         Container,
         Header,
         Button} from 'semantic-ui-react';
import {URI} from '../config'
import PagesTemplate from './templates/PagesTemplate'
import { DragDropContext, Droppable } from "react-beautiful-dnd";

const reorderPages = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default class Pages extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            ready : false,
            result : null,
            disabled: true
        };
        this.addPage = this.addPage.bind(this)
        this.onDragEnd = this.onDragEnd.bind(this);
    }
    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorderPages(
            this.state.result.data,
            result.source.index,
            result.destination.index
        );
        const errors = {}

        this.setState({
            result : {
                data: items,
                errors
            },
            items: items,
            disabled : false
        });
    }
    updatePages(page = '1') {
      fetch( URI + '/page/list-view', { method : 'POST', credentials: "same-origin", body : JSON.stringify({ page: page })  } )
        .then(res => res.json())
        .then( ({data, errors}) => this.setState({
          ready : true,
          result : {
            data,
            errors
          }
        }))
        .catch(e => this.setState({ ready : true, result : { errors : {error : e.message } }}));
    }
    componentDidMount() {
        this.updatePages()
    }
    render() {
        const { ready, result } = this.state
        if(!ready)
            return (
                <div className="ui active centered inline loader"></div>
            )
        if( result &&  Object.keys(result.errors).length )
            return(
                <div>
                    500 - Something went wrong
                </div>
            )
        return (
            <>
                <Container>
                    <Header as='h2' floated='left'>Pagini</Header>
                    <NavLink to='/cabinet/page/add' ><Button floated='right' className="category__add">Adaugă pagina</Button></NavLink>
                </Container>
                <Table celled padded centered={"true"}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>ID:</Table.HeaderCell>
                            <Table.HeaderCell>Titlu RO:</Table.HeaderCell>
                            <Table.HeaderCell>Titlu RU:</Table.HeaderCell>
                            <Table.HeaderCell>Editare:</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable-1" index={0}>
                                {(provided, snapshot) => (
                                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                        { result && result.data.map( (dataPages, index) => {
                                            let showPageFunction = this.showPage.bind(this, dataPages._id, dataPages.sw_active, index)
                                            let deletePageFunction = this.deletePage.bind(this, dataPages._id, index)
                                            return <PagesTemplate index={index} key={ dataPages._id } dataPages={ dataPages } editPage={this.editPage} showPage={ showPageFunction } deletePage={ deletePageFunction } />
                                        }) }
                                        {provided.placeholder}
                                    </tbody>
                                )}
                          </Droppable>
                    </DragDropContext>
                </Table>
                <Container>
                    { this.state.disabled ?
                        <Button floated="right">
                            Editează ordinea
                        </Button>
                            :
                        <Button className="button__green" floated="right" onClick={this.addNewOrder}>
                            Salvează ordinea
                        </Button>
                    }
                </Container>
            </>
        );
    }
    showPage = ( id, sw_active, index ) => fetch( URI + '/page/view', { method: 'PUT', credentials: "same-origin", body: JSON.stringify( { id, action : sw_active ? 'hide' : 'show' } )  } )
                                      .then( ressult => ressult.json() )
                                      .then( json => this.setState(prevState => {
                                              let newData = [ ...prevState.result.data ]
                                              newData.splice(index, 1, json.data)
                                                  return { result: {...prevState.result, data: newData} }
                                          })
                                      )
    deletePage = ( id, index ) => fetch( URI + '/page/view', { method: 'DELETE', credentials: "same-origin", body: JSON.stringify( { id } ) } )
                                     .then( ressult => ressult.json() )
                                     .then( json => this.setState(prevState => {
                                            let newData = [ ...prevState.result.data ]
                                            newData.splice(index, 1)
                                                return { result: {...prevState.result, data: newData} }
                                         })
                                     )
     addPage = async (e) => {
         e.preventDefault()
         let submitData = new FormData(e.currentTarget),
             image = submitData.get('icon'),
             type = parseInt(submitData.get('type')),
             fileRead = new FileReader(),
             file = image ? await new Promise( (resolve, reject) => {
                 fileRead.onloadend = () => resolve(fileRead.result)
                 try {
                     fileRead.readAsDataURL(image)
                 }
                 catch(e) {
                     reject(console.log(e.message))
                 }
             } ) : null
             submitData.set('icon', file)
             submitData.set('type', type)
             let object = {};
             submitData.forEach((value, key) => {object[key] = value});
             let json = JSON.stringify(object);
         fetch( URI + '/page/view', { method: 'POST', credentials: "same-origin", body: json } )
         .then( ressult => ressult.json())
         .then( json => this.setState(prevState => {
                 this.updatePages()
               })
             )
         }
     editPage = async (e) => {
         e.preventDefault()
         const submitData = new FormData(e.currentTarget),
               fileRead = new FileReader(),
               json = JSON.stringify({
             'title.ru': submitData.get('title.ru'),
             'title.ro': submitData.get('title.ro'),
             'description.ru': submitData.get('description.ru'),
             'description.ro': submitData.get('description.ro'),
             'cover_img': submitData.get('cover_img') ? await new Promise( (resolve, reject) => {
                 fileRead.onloadend = () => resolve(fileRead.result)
                 try {
                     fileRead.readAsDataURL(submitData.get('cover_img'))
                 }
                 catch(e) {
                     reject(console.log(e.message))
                 }
             } ) : null,
             'category_id': parseInt(submitData.get('category_id')),
         });
         await fetch( URI + '/page/view', { method: 'POST', credentials: "same-origin", body: json } );
     }
     addNewOrder = ( id, index ) => {
         const {items} =  this.state,
                 data = items.map(({_id}, index) => (
                     {id: _id, order: index})
                 )
         const submitData = JSON.stringify(
             {items: data}
         )
         fetch( URI + '/page/save-orders', { method: 'POST', credentials: "same-origin", body: submitData } )
                                      .then( ressult => ressult.json() )
                                      .then( ({data, errors}) => this.setState({
                                          ready : true,
                                          disabled: true
                                      }))
                                      .catch(e => this.setState({ ready : true, result : { errors : {error : e.message } }}));
     }
}
