import React from 'react';
import { NavLink } from 'react-router-dom';
import PointsTemplate from './templates/PointsTemplate';
import {Table,
        Container,
        Header,
        Button} from 'semantic-ui-react';
import { URI } from '../config';

export default class Points extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            ready : false,
            result : []
        };
    }
    updatePages(){
        const page = 1
        fetch( URI + `/map-point/list-view?page=${page}`, { method : 'GET', credentials: "same-origin" } )
            .then(res => res.json())
            .then( ({data, errors}) => this.setState({
                ready : true,
                result : {
                    data,
                    errors
                }
            }))
            .catch(e => this.setState({ ready : true, result : { errors : {error : e.message } }}));
    }
    componentDidMount() {
        this.updatePages()
    }
    render() {
        const { ready, result } = this.state
        if(!ready)
            return (
                <div className="ui active centered inline loader"></div>
            )
        if( result &&  Object.keys(result.errors).length )
            return(
                <div>
                    500 - Something went wrong
                </div>
            )
        return (
            <>
                <Container>
                    <Header as='h2' floated='left'>Locații pe hartă</Header>
                    <NavLink to='/cabinet/points/add' ><Button floated='right' className="category__add">Adaugă o locație pe hartă</Button></NavLink>
                </Container>
                <Table celled padded centered={"true"}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>ID:</Table.HeaderCell>
                            <Table.HeaderCell>Numele organizației:</Table.HeaderCell>
                            <Table.HeaderCell>Adresa:</Table.HeaderCell>
                            <Table.HeaderCell>Editare:</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        { result.data && result.data.map( (dataUsers, index) => {
                            let showUserFunction = this.showUser.bind( this, dataUsers._id, dataUsers.sw_active, index )
                            let deleteUserFunction = this.deleteUser.bind(this, dataUsers._id)
                            return <PointsTemplate key={ dataUsers._id } showUser={showUserFunction} deleteUser={deleteUserFunction}  dataUsers={ dataUsers } />
                        }) }
                    </Table.Body>
                </Table>
            </>
        )
    }
    showUser = ( id, sw_active, index ) => fetch( URI + '/map-point/view', { method: 'PUT', credentials: "same-origin", body: JSON.stringify( { id, action : sw_active ? 'hide' : 'show' } )  } )
                                      .then( ressult => ressult.json() )
                                      .then( json => this.setState(prevState => this.updatePages()))

    deleteUser = ( id, index ) => fetch( URI + '/map-point/view', { method: 'DELETE', credentials: "same-origin", body: JSON.stringify( { id } ) } )
                                     .then( ressult => ressult.json() )
                                     .then( json => this.setState(prevState => this.updatePages()))
    addPoint = async (e) => {
        e.preventDefault()
        const submitData = new FormData(e.currentTarget);
        const json = JSON.stringify({
          'name': submitData.get('name'),
          'surname': submitData.get('surname'),
          idnp: submitData.get('idnp'),
          email: submitData.get('email'),
          type: parseInt(submitData.get('type'))
        });
        await fetch( URI + '/map-point/view', { method: 'POST', credentials: "same-origin", body: json } )
        this.setState(prevState => this.updatePages())
    }
}
