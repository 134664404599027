import React from 'react';
import RedirectsItem from './templates/RedirectsItem';
import RedirectsFilter from '../components/RedirectsFilter';
import { Table,
         Container,
         Header,
         Button } from 'semantic-ui-react';
import { URI } from '../config';
import axios from 'axios';
import Pagination from "react-pagination-bootstrap";

export default class Redirected extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        result : [],
        activePage: 1,
        requestData: '',
        lang : this.props.lang,
        count: 1,
    };
    this.updatePages = this.updatePages.bind(this)
  }

  handlePageChange(pageNumber) {
    this.setState({
        activePage: pageNumber
    });
    this.state.requestData ? this.updatePages(pageNumber, this.state.requestData) : this.updatePages(pageNumber)
  };

  updatePages(pageNumber, params){
      const paramsData = params ? Object.assign(params, {page: pageNumber}) : {page: pageNumber};
      axios.get( URI + `/sick-report/list-view/`, {params: paramsData})
      .then( ({data}) => {
          this.setState({
              ready : true,
              activePage: pageNumber,
              requestData: params,
              result : {
                  count: data.count,
                  data: data.data,
                  errors: data.errors
              }
          })
      })
  }

  componentDidMount() {
      this.updatePages(this.state.activePage)
  }

  render() {

    const { ready, result, activePage, requestData } = this.state;
    return (
        <>
            <Container>
                <Header as='h2'>
                    <span>Redirecționari:</span>
                    <span
                        style={{
                            paddingLeft:'5px',
                            fontWeight: '600',
                            fontSize: '20px'}}
                        >
                            {result.count}
                        </span>
                </Header>

                <RedirectsFilter
                    onChangeData={this.updatePages}
                    setRequestData={this.setRequestData}
                />
            </Container>
            <Table celled padded centered={"true"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>N:</Table.HeaderCell>
                        <Table.HeaderCell>Data:</Table.HeaderCell>
                        <Table.HeaderCell>Nume/Prenume:</Table.HeaderCell>
                        <Table.HeaderCell>Numărul legitimației:</Table.HeaderCell>
                        <Table.HeaderCell>Subdiviziuni:</Table.HeaderCell>
                        <Table.HeaderCell>Acţiuni:</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    { result.data && result.data.map( (item, index) => {
                        return (
                            <RedirectsItem
                                key={ index }
                                dataIndex={index+1}
                                dataRedirect={ item }
                            />
                        )
                    }) }
                </Table.Body>
            </Table>
            <Pagination
              activePage={activePage}
              itemsCountPerPage={50}
              totalItemsCount={this.state.result.count}
              onChange={this.handlePageChange.bind(this)}
            />
        </>
    )
  }

}
