import React from 'react';
import { Container,
         Button } from 'semantic-ui-react';
import {URI} from '../config';

import CategoryTemplate from './templates/CategoryTemplate';
import CategoryPopup from './CategoryPopup';
import { DragDropContext, Droppable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const subReorder = (parent_id, list, startIndex, endIndex) => {
    let result = [];
    let items = Array.from(list);
    list.map( (categories, index) => {
        if (categories.children.length && categories._id == parent_id){
            result = categories.children
            items[index].children = result
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

        }
    })

    return items;
};

export default class Category extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            ready : false,
            result : null,
            disabled: true,
            disabledCategory: false,
        };
        this.addCategory = this.addCategory.bind(this)
        this.onDragEnd = this.onDragEnd.bind(this);
        this.closePopup = this.closePopup.bind(this);
    };

    closePopup(close) {
        close()
    }

    updatePages(){
      fetch( URI + '/category/list-view', { method : 'GET', credentials: "same-origin" } )
        .then(res => res.json())
        .then( ({data, errors}) => {
            this.setState({
              ready : true,
              result : {
                data,
                errors
              },
              parent_categories: {
                data
              }
          })
        })
        .catch(e => this.setState({ ready : true, result : { errors : {error : e.message } }}));
    }

    componentDidMount() {
        this.updatePages()
    }

    onDragEnd(result) {

        if (!result.destination) {
            return;
        }

        let items = [];

        if (result.type === "CATEGORY") {
            // dropped outside the list
            items = reorder(
                this.state.result.data.categories,
                result.source.index,
                result.destination.index
            );
        } else if (result.type === "SUBCATEGORY") {
            items = subReorder(
                result.destination.droppableId,
                this.state.result.data.categories,
                result.source.index,
                result.destination.index
            );
        } else {
            return null
        }

        const errors = {}

        this.setState({
            result : {
                data: {
                    categories: items,
                },
                errors
            },
            items: items,
            disabled : false
        });

        // if (result.type === "SUBCATEGORY") {
        //     console.log(result);
        //     const questions = reorder(
        //         this.state.result.data,
        //         result.source.index,
        //         result.destination.index
        //     );
        //
        //     this.setState({
        //         questions
        //     });
        // } else {
        //     const answers = reorder(
        //         this.state.result.data.children,
        //         result.source.index,
        //         result.destination.index
        //     );
        //
        //     const questions = JSON.parse(JSON.stringify(this.state.result.data.children));
        //
        //     questions[result.type].answers = answers;
        //
        //     this.setState({
        //         questions
        //     });
        // }
    };

    render() {

        const { ready, result, parent_categories } = this.state,
              addButton = <Button floated='right' className="category__add">Adaugă categorie</Button>;
        if(!ready)
            return (
                <div className="ui active centered inline loader"></div>
            )
        if( result && Object.keys(result.errors).length )
            return(
                <div>
                    500 - Something went wrong
                </div>
            )
        const parentCategories = parent_categories && parent_categories.data.parent_categories
        const finalparentCategories = parentCategories.concat({
          _id: 0,
          icon: "",
          parent_id: '',
          sw_active: false,
          title: "-----------",
          type: 2
        })
        const fiteredParentCategories = finalparentCategories.map((key) => {
            return {
                key: key._id,
                text: key.title,
                value: key._id
            }
        });
        return (
            <>
                <div className='container-menu'>
                    <h2>Categorii</h2>
                    <CategoryPopup
                        trigger = { addButton }
                        addCategory = { this.addCategory }
                        closePopup = { this.closePopup }
                        fiteredParentCategories = {fiteredParentCategories}
                         />
                 </div>
                <div className='container' centered={"true"}>
                    <div className='table-header'>
                        <div>ID:</div>
                        <div>Titlu RO:</div>
                        <div>Titlu RU:</div>
                        <div>Editare:</div>
                    </div>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable" index={0} type="CATEGORY">
                                {(provided, snapshot) => (
                                    <div className='table-of-tr' ref={provided.innerRef} {...provided.droppableProps} type="CATEGORY">
                                            { result && result.data.categories.map( (dataCategory, index) => {
                                            let showCategoryFunction = this.showCategory.bind( this, index )
                                            let deleteCategoryFunction = this.deleteCategory.bind(this, index)
                                                return <CategoryTemplate
                                                            index={index}
                                                            key={ dataCategory._id }
                                                            props={this.props}
                                                            dataCategory={ dataCategory }
                                                            fiteredParentCategories={fiteredParentCategories}
                                                            addCategory={ this.addCategory }
                                                            showCategory={ showCategoryFunction }
                                                            deleteCategory={ deleteCategoryFunction }
                                                            onDragEnd={ this.onDragEnd }
                                                        />
                                            }) }
                                        {provided.placeholder}
                                    </div>
                                )}
                          </Droppable>
                    </DragDropContext>
                </div>
                <Container>
                    { this.state.disabled ?
                        <Button floated="right">
                            Editează ordinea
                        </Button>
                            :
                        <Button className="button__green" floated="right" onClick={this.addNewOrder}>
                            Salvează ordinea
                        </Button>
                    }
                </Container>
            </>
        );
    }
    showCategory = ( index, id, sw_active ) => fetch( URI + '/category/view', { method: 'PUT', credentials: "same-origin", body: JSON.stringify( { id, action : sw_active ? 'hide' : 'show' } )  } )
                                               .then( ressult => ressult.json())
                                               .then( json => this.setState(prevState => this.updatePages()));
    deleteCategory = ( index, id ) => fetch( URI + '/category/view', { method: 'DELETE', credentials: "same-origin", body: JSON.stringify( { id } ) } )
                                      .then( ressult => ressult.json() )
                                      .then( json => this.setState(prevState => this.updatePages()) );
    addCategory = async (e, data, close) => {
        const pageId = e.category_id.value || 0;
        const submitData = new FormData(data.target);
        const fileRead = new FileReader(),
              json = JSON.stringify({
            'title.ru': e.title_ru,
            'title.ro': e.title_ro,
            type: parseInt(e.type),
            is_redirection: parseInt(e.is_redirection) === 2 ? true : false,
            '_id': parseInt(e.category_id),
            parent_id: parseInt(data.target.parent_id.defaultValue),
            icon: submitData.get('icon') ? await new Promise( (resolve, reject) => {
                fileRead.onloadend = () => resolve(fileRead.result)
                try {
                    fileRead.readAsDataURL(submitData.get('icon'))
                }
                catch(e) {
                    reject(console.log(e.message))
                }
            } ) : ''
        });
        await fetch( URI + `/category/view?_id=${pageId ? pageId : 0}`, { method: 'POST', credentials: "same-origin", body:json } );
        this.setState(prevState => this.updatePages());
        close();
    }
    addNewOrder = ( id, index ) => {
        const {items} =  this.state,
                data = items.map(({_id}, index) => (
                    {id: _id, order: index})
                )
        items && items.map( (item, index) => {
            item.children.length && item.children.map( (child, i) => {
                data.push({id: child._id, order: i })
            })
        } )
        const submitData = JSON.stringify(
            {items: data}
        )
        fetch( URI + '/category/save-orders', { method: 'POST', credentials: "same-origin", body: submitData } )
                                     .then( ressult => ressult.json() )
                                     .then( ({data, errors}) => this.setState({
                                         ready : true,
                                         disabled: true
                                     }))
                                     .catch(e => this.setState({ ready : true, result : { errors : {error : e.message } }}));
    }
}
