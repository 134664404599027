import React, { Component } from 'react';
import styled from 'styled-components';
import mapboxgl from 'mapbox-gl';

import mapMarker from '../../../images/ic_place.svg';
import geolocationMarker from '../../../images/near_me.svg';
import pointLogo from '../../../images/map_logo.svg';
import pointLogoSate from '../../../images/pointLogoSatellite.svg';
import plus from '../../../images/add.svg';
import minus from '../../../images/remove.svg';
import satellite from '../../../images/satellite.png';

// MAP.MD AUTHORIZATION
const API_KEY = 'cc597821-5e82-4c70-b42f-9b39de6fab9b';
const ENCODE = Buffer.from(`${API_KEY}:xxx`).toString('base64');
const AUTHORIZATION = `Basic ${ENCODE}`;
const REQUEST_OPTIONS = {
  headers: {
    Authorization: AUTHORIZATION,
  },
};

let mapStyle;
fetch('https://map.md/api/tiles/styles/map/style.json', REQUEST_OPTIONS)
  .then(resp => resp.json())
  .then(json => (mapStyle = json))
  .catch(err => console.log(err));

const ContainerName = styled.div`
  text-transform: uppercase;
  color: #7f98ac;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
`;

const SatelliteDiv = styled.div`
  position: absolute;
  background-image: url(${satellite});
  right: 15px;
  bottom: 15px;
  z-index: 1;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  overflow: hidden;
  border: 3px solid white;
  background-position: 100%;
  &.active {
    background-position: 0%;
  }
  :hover {
    cursor: pointer;
  }
  > img {
    max-width: 50px;
    max-height: 50px;
  }
`;

const PointLogo = styled.div`
  position: absolute;
  bottom: 15px;
  left: 30px;
  z-index: 1;
  display: flex;
  img {
    width: 44px;
    height: 17px;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 381px;
  position: relative;
  .marker {
    background-image: url(${mapMarker});
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .mapboxgl-ctrl-bottom-right {
    display: none;
  }
`;

const MapContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .mapboxgl-ctrl-logo {
    display: none;
  }
  .mapboxgl-ctrl-icon.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-waiting {
    animation: pulse 2s infinite;
  }
  .mapboxgl-user-location-dot {
    background-color: #35b84a;
    :before {
      background-color: #35b84a;
    }
  }
  .mapboxgl-ctrl-logo {
    background-image: url(${pointLogo});
    width: 46px;
    height: 19px;
  }
  .mapboxgl-ctrl-top-right {
    top: 5px;
    right: 5px;
    div:nth-child(2) {
      border-radius: 50px;
      background-color: #fff !important;
      > button {
        height: 34px;
        width: 34px;
        background-color: #ffffff;
        background-image: url(${geolocationMarker});
        background-position: center;
        background-repeat: no-repeat;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.07);
      }
    }
    div:nth-child(1) {
      border-radius: 50px;
      background-color: #ffffff;
      width: 34px;
      > button {
        height: 39px;
        width: 34px;
        margin: 0 auto;
        background-color: #ffffff;
        background-position: center;
        background-repeat: no-repeat;
        :active {
          background: rgb(252, 252, 252) none repeat scroll 0% 0%;
        }
      }
      button:nth-child(1) {
        background-image: url(${plus});
        background-position: center;
        background-repeat: no-repeat;
      }
      button:nth-child(2) {
        background-image: url(${minus});
        background-position: center;
        background-repeat: no-repeat;
        border-top: none;
        position: relative;
        :before {
          content: '';
          position: absolute;
          background-color: #ededed;
          height: 1px;
          width: 18px;
          top: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
    }
  }
`;

class SingleElemMap extends Component {
  state = {
    lng: 0,
    lat: 0,
    zoom: 12,
    mapOn: false,
    satellite: false,
  };

  async componentDidMount() {

    const { lng, lat, zoom } = this.state;
    const { coordinates, changeCoordinates } = this.props;
    const { address, changeAdressParent } = this.props;
    const { region, changeRegionParent } = this.props;
    const mapMarkerAndCenter = [coordinates.lng || lng, coordinates.lat || lat];

    mapboxgl.accessToken = 'pk.eyJ1IjoiZ2l0NXgiLCJhIjoiY2p2eHFveGlwMDdhODRjcnptZDVxZnIyaCJ9.l_dEnhIV4TlXpU0jgj8ELg';
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: mapStyle,
      center: mapMarkerAndCenter,
      zoom,
      // transformRequest: url => ({
      //   url,
      //   ...REQUEST_OPTIONS
      // })
    });

    this.map.on('click', data => {
      const newLat = data.lngLat.lat;
      const newLng = data.lngLat.lng;
      this.setState(
        {coordinates:
            {
              lng: newLng,
              lat: newLat,
          }
        },
        () => {
          changeCoordinates({ lng: newLng, lat: newLat });
        }
      );
    });

    this.map.addControl(
      new mapboxgl.NavigationControl({
        showCompass: false,
      })
    );

    this.map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      })
    );

    this.map.scrollZoom.disable();
  }

  async shouldComponentUpdate(nextProps) {

    const { changeAdress, chengeSectorData } = this.props;
    const { address, changeAdressParent } = this.props;
    const { region, changeRegionParent } = this.props;
    const { coordinates } = nextProps;
    const {
      coordinates: { lat, lng },
    } = this.props;
    // console.log(coordinates)
    const mapMarkerAndCenter = [coordinates.lng, coordinates.lat];
    // console.log(mapMarkerAndCenter)


    if (!this.map) return true;

    const checkMarker = document.querySelector('.marker');
    if (lng === coordinates.lng && lat === coordinates.lat && checkMarker) return false;

    const el = checkMarker || document.createElement('div');
    el.className = 'marker';
    if (coordinates.lng && coordinates.lat && mapMarkerAndCenter[0] !== 28.840094966238524 && mapMarkerAndCenter[1] !== 47.02348495743132) {
      new mapboxgl.Marker(el).setLngLat(mapMarkerAndCenter).addTo(this.map);

      this.map.flyTo({ center: mapMarkerAndCenter });
      await fetch(`https://map.md/api/companies/webmap/near?lat=${coordinates.lat}&lon=${coordinates.lng}`, REQUEST_OPTIONS)
        .then(resp => resp.json())
        .then(data => {
            // if (data && data.building && (data.building.location === 'Chișinău' || data.building.location === 'Chişinău')) {
            if (data.city.is_in == 'Moldova'){
                changeRegionParent(`${data.city.name}`)
            } else {
                changeRegionParent(`${data.city.is_in}`)
            }
            if (data.building && data.building.street_name && data.building.number) {
              changeAdressParent(`${data.city.name}, ${data.building.street_name}, ${data.building.number}`);
              changeAdress(`${data.city.name}, ${data.building.street_name}, ${data.building.number}`);
              // fetch(`https://map.md/api/companies/webmap/city?lat=${coordinates.lat}&lon=${coordinates.lng}`, REQUEST_OPTIONS)
              //   .then(info => info.json())
              //   .then(jsonData =>
              //     chengeSectorData({
              //       id: jsonData.id.toString(),
              //       geolocation_lat: coordinates.lat.toString(),
              //       geolocation_lng: coordinates.lng.toString(),
              //     })
              //   )
              //   .catch(error => console.log(error));
            // } else {
            //   changeAdressParent(`${data.city.name}`);
            //   changeAdress(`${data.city.name}`);
            //   //   chengeSectorData({});
            // }
          } else {
            changeAdressParent('');
            changeAdress('');
            chengeSectorData({});
          }
        })
        .catch(err => console.log(err));
    }
    return true;
  }

  handleSatellite() {
    this.setState(prevState => ({ satellite: !prevState.satellite }));
    if (this.map.style.stylesheet.id === 'vector-ck2j2fx81') {
      this.map.setStyle('mapbox://styles/mapbox/satellite-streets-v11');
    } else {
      this.map.setStyle(mapStyle);
    }
  }

  componentWillUnmount() {
    if (this.map) this.map.remove();
  }

  render() {
    const { t } = this.props;
    const { mapOn, satellite } = this.state;
    if (!mapOn) {
      setTimeout(() => {
        this.setState({
          mapOn: !!this.map,
        });
      }, 700);
    }
    return (
      <>
        <ContainerName></ContainerName>
        <Container>
          <MapContainer
            ref={el => (this.mapContainer = el)}
            onClick={ () =>{
                    this.props.changeSubmitCoordinates(this.props.coordinates);
                    this.props.changeAdressParent(this.props.address)
                    this.props.changeRegionParent(this.props.region)
                }
            }
          />
          <PointLogo>
            <a href="//map.md" rel="noopener noreferrer" target="_blank">
              <img src={`${!satellite ? pointLogo : pointLogoSate}`} alt="map-logo" />
            </a>
          </PointLogo>
          <SatelliteDiv className={satellite ? 'active' : ''} onClick={() => this.handleSatellite()} />
        </Container>
      </>
    );
  }
}

export default SingleElemMap;
