import React, { useState } from 'react';
import logo from '../css/img.png'

const handleChange = (event, changeFile) => {
  changeFile( URL.createObjectURL(event.target.files[0]) )
}

export default ({iconed}) => {
    const [ file, changeFile ] = useState(iconed || null)
    return (
        <>
            { <img className='img-content image icon' alt="icon-category-empty" src={ file || logo} /> }
            <label className="modal__image_button">
                Adaugă imagine
                <input type="file"
                       name="icon"
                       accept='.jpg, .png, .jpeg, .svg'
                       style={{ display : 'none' }}
                       onChange={ (e) => handleChange(e, changeFile) }
                       />
            </label>
        </>
    );
}
