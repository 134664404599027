import React from 'react';
import {Icon} from 'semantic-ui-react';
import CategoryPopup from '../CategoryPopup';
import {Draggable} from "react-beautiful-dnd";


export default function Subcategory( { dataCategory, fiteredParentCategories, deleteCategory, showCategory, index, addCategory } ) {
    const  { _id, title, sw_active } = dataCategory,
           showSubCategory = () => showCategory(_id, sw_active),
           deleteSubCategory = () => deleteCategory( _id );
    return (
        <Draggable key={index} draggableId={dataCategory._id} index={index}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                  <div>
                      { _id }
                  </div>
                  <div>
                    { title && title.ro }
                  </div>
                  <div>
                    { title && title.ru }
                  </div>
                  <div>
                      <div className='category__edit'>
                          <Icon name={`eye${!sw_active ? ' slash' : ''}`} onClick={showSubCategory} />
                          <CategoryPopup
                              trigger = { <Icon name='pencil' /> } addCategory={addCategory} fiteredParentCategories={fiteredParentCategories} showCategory={showCategory} catInfo={ dataCategory } />
                          <Icon name='delete' onClick={ deleteSubCategory }  />
                      </div>
                  </div>
                  {provided.placeholder}
              </div>
          )}
        </Draggable>
    )
}
