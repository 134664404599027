import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Dropdown, Form, Input, TextArea } from 'semantic-ui-react';

const Container = styled.div`
  width: 100%;
`;

const DivElement = styled.div`
    &.name-selector:after{
        margin: -.2em 0 0 .2em;
        content: '*';
        color: #db2828;
        display: inline-block;
    }
`;



const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1em;
  div.name-selector {
    margin-bottom: .29rem
  }
  input,
  div.selector {
    width: 100%;
  }
`;

const checkForStreetsValue = (searchInput, changeGetAdress, changeAdress, changeCoordinates, changeAdressParent, changeSubmitCoordinates) => {
  if (searchInput.length > 3) {
    const url = `https://map.md/ru/map/webmap/search?q=${searchInput}`;
    fetch(url)
      .then(resp => resp.json())
      .then(data => {
          // console.log(data)
        const maxValuesToShow = 90;
        if (data && !data.selected) {
          const newArrayWithStreets = data.list.reduce((acc, el) => {
            // if (el.parent_name && acc.length < maxValuesToShow) {
            //     const newCityValue = el.parent_name.map(e => console.log(el))
            // }
            if (el.type == "street" && el.buildings && el.buildings.length && acc.length < maxValuesToShow) {
              const newStreesValue = el.buildings.map(e => acc.length < maxValuesToShow && `${el.parent_name}, ${el.name}, ${e}`);
              return [...acc, ...newStreesValue];
            }
            return acc;
          }, []);
          changeGetAdress(newArrayWithStreets);
        } else {
          const {
            location,
            street_name,
            number,
            centroid: { lon, lat },
          } = data.selected;
          // console.log(Number(lon),  Number(lat))

          const streetSelected = `${location}, ${street_name}, ${number}`;
          changeGetAdress([streetSelected]);
          changeCoordinates({ lng: Number(lon), lat: Number(lat) });
          changeAdress(streetSelected);
          changeSubmitCoordinates({ lng: Number(lon), lat: Number(lat) })
          changeAdressParent(streetSelected, lon, lat);
        }
      })
      .catch(err => console.log(err));
  }
};

const Inputs = ({
  t,
  categories,
  errors,
  currentRegion,
  address,
  changeCategoryID,
  checkedCategoryID,
  currentAdress,
  changeAdress,
  currentDescription,
  changeDescription,
  changeCoordinates,
  changeAdressParent,
  changeSubmitCoordinates
}) => {
  const [getAdress, changeGetAdress] = useState([]);
  const [translatedAdress, chengeTranslatedAdress] = useState([]);
  useEffect(() => {
    chengeTranslatedAdress(
      getAdress.length
        ? getAdress.map((el, i) => ({ key: i, value: `${el}`, text: `${el}` }))
        : [{ key: 0, value: currentAdress, text: currentAdress }]
    );
  }, [getAdress, currentAdress]);

  useEffect(() => {
    chengeTranslatedAdress(currentAdress.length ? [{ key: 0, value: currentAdress, text: currentAdress }] : []);
  }, [currentAdress]);

  const dropDownData = categories.map((e, i) => ({
    key: i,
    value: e.category_id,
    text: e.category_name,
  }));
  return (
    <Container>
      <InputsContainer>
        <InputLayout>
          <DivElement className="name-selector">{'Strada'}</DivElement>
          <Dropdown
            placeholder={'Strada'}
            noResultsMessage={'Strada'}
            value={currentAdress}
            selection
            lazyLoad
            error={errors.includes('currentAdress')}
            search={options => options}
            options={translatedAdress}
            selectOnNavigation={false}
            onSearchChange={(_, { searchQuery }) => checkForStreetsValue(searchQuery, changeGetAdress, changeAdress, changeCoordinates, changeAdressParent, changeSubmitCoordinates)}
            onChange={(_, { value }) => {
                    // changeAdressParent(value)
                    // console.log(value)
                    changeSubmitCoordinates(value)
                    checkForStreetsValue(value, changeGetAdress, changeAdress, changeCoordinates, changeAdressParent, changeSubmitCoordinates);
                }
            }
          />
        </InputLayout>
      </InputsContainer>
      <InputsContainer>
        <InputLayout>
          <DivElement>{'Region'}</DivElement>
          <Input
              disabled
              type="text"
              value={currentRegion}
            />
        </InputLayout>
      </InputsContainer>
    </Container>
  );
};

export default Inputs;
