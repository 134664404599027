import React  from 'react';
import { Header,
         Button,
         Icon,
         Input } from 'semantic-ui-react';
import { URI } from '../config';
import ModalImg from './ModalImgPages';
import { Editor } from '@tinymce/tinymce-react';
import 'draft-js-video-plugin/lib/plugin.css';
// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import FormErrors from './Validate/FormErrors'; 
// Import all Froala Editor plugins;
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';

export default class PagesAdd extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            ready : false,
            result : null,
            content: "",
            modelRu: 'Example text',
            modelRo: 'Example text',
            titleRu: '',
            titleRo: '' ,
            contentHtmlRu: '',
            contentHtmlRo: '',
            formErrors: {titleRu: '', titleRo: ''},
            titleRuValid: false,
            titleRoValid: false,
            formValid: false,
            noImage: false,
            cover_imgBack: false,
        };
        this.ref = null;
        this.addPage = this.addPage.bind(this);
    }

    componentDidMount() {
        const pageId = this.props.match.params.id;
        fetch( URI + `/page/view?id=${pageId ? pageId : 0}`, { method : 'GET', credentials: "same-origin" } )
            .then(res => res.json())
            .then( ({data, errors}) => {
                this.setState({
                    ready : true,
                    result : {
                        data,
                        errors,
                    },
                    options: data.categories && data.categories,
                    cover_img: data && data.page.cover_img,
                    modelRu: data.page.description ? data.page.description.ru : ' ',
                    modelRo: data.page.description ? data.page.description.ro : ' ',
                    titleRu: data.page.title && data.page.title.ru ? data.page.title.ru : '',
                    titleRo: data.page.title && data.page.title.ro ? data.page.title.ro : '',
                    selectedCategory: data.page.category_id && data.page.category_id ? data.page.category_id : '',
                    formValid: data.page.title && data.page.title.ru ? true : false,
                    cover_imgBack: data.page.cover_img ? true : false,
                })
        })
        .catch(e => this.setState({ ready : true, result : { errors : {error : e.message } }}));
    }

    handleModelChangeRu = (e) => {
        this.setState({
          modelRu: e.target.getContent()
        });
    }

    handleModelChangeRo = (e) => {
        this.setState({
          modelRo: e.target.getContent()
        });
        // console.log(e.target.getContent())
    }

    handleUserInput(e) {
      const name = e.target.name,
            value = e.target.value;
      this.setState({[name]: value}, () => { this.validateField(name, value) });
    }

    validateField(fieldName, value) {
      let fieldValidationErrors = this.state.formErrors;
      let titleRuValid = this.state.titleRuValid;
      let titleRoValid = this.state.titleRoValid;

      switch(fieldName) {
        case 'titleRu':
          titleRuValid = value.length >= 5;
          fieldValidationErrors.titleRu = titleRuValid ? '' : ' este scurt';
          break;
        case 'titleRo':
          titleRoValid = value.length >= 5;
          fieldValidationErrors.titleRo = titleRoValid ? '': ' este scurt';
          break;
        default:
          break;
      }
      this.setState({
            formErrors: fieldValidationErrors,
            titleRuValid: titleRuValid,
            titleRoValid: titleRoValid
        }, this.validateForm
      );
    }

    validateForm() {
      this.setState({
          formValid: this.state.titleRuValid && this.state.titleRoValid
        });
    }

    errorClass(error) {
       return(error.length === 0 ? '' : 'has-error');
    }
    
    render() {
        const { ready, result, options, modelRo, modelRu, titleRu, titleRo, noImage } = this.state;
        if(!ready)
            return (
                <div className="ui active centered inline loader"> </div>
            );
        if( result &&  Object.keys(result.errors).length )
            return(
                <div>
                    500 - Something went wrong
                </div>
            );
        return (
                <form onSubmit={ this.addPage } className="modal_page">
                    <Header>
                        Adaugă o pagină
                    </Header>
                    <Header className="modal__top" dividing>
                        <div className="modal__top__title">
                            <span>ID categorie: </span>
                        </div>
                        { this.data && <div> <Icon name={`eye${this.data.sw_active ? ' slash' : ''}`} onClick={ this.showPage }  /> </div> }
                    </Header>
                    <Header as="h5">
                        <span className="modal__title__parent">Categorie părinte</span>
                        <select className="modal__title__select pages" name="category_id" value={this.state.selectedCategory} onChange={(e) => this.setState({selectedCategory: e.target.value, validationError: e.target.value === "" ? "You must select your favourite team" : ""})}>
                            {options && options.map( model => <option value={model._id} key={model._id}>{model.title}</option>)}
                        </select>
                    </Header>

                    <Header as="h5">
                        <span className="modal__title">Titlu RU: <span style={{color: 'red'}}>*</span></span>
                    </Header>
                    <Input fluid name="titleRu" value={titleRu} onChange={(e) => this.handleUserInput(e)} placeholder='Titlu RU'  />
                    <Header as="h5">
                        <span className="modal__title">Descrierea Ru:</span>
                    </Header>

                    <Editor
                        apiKey='hze0gfshyw4fs3pird0o18w32momr2m3bc72mk64htp3ro44'
                        initialValue={modelRu}
                        init={{
                            height: 500,
                            menubar: 'file edit view insert format tools table tc help',
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor', 'searchreplace visualblocks code fullscreen', 'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                            ,
                            images_upload_handler: function (blobInfo, success, failure) {
                                var xhr, formData;
                                formData = new FormData();
                                formData.append('image', blobInfo.blob(), blobInfo.filename());
                                xhr = new XMLHttpRequest();
                                xhr.open('POST', 'https://api.imgur.com/3/image');

                                xhr.setRequestHeader('Authorization', 'Client-ID 8d26ccd12712fca');
                                xhr.send(formData);
                                xhr.onload = function() {
                                  var json;
                            
                                  if (xhr.status !== 200) {
                                    failure('HTTP Error: ' + xhr.status);
                                    return;
                                  }
                            
                                  json = JSON.parse(xhr.responseText);
                            
                                  if (!json || typeof json.location !== 'string') {
                                    success(json.data.link);
                                    return ;
                                  }
                            
                                  success(json.location);
                                };
                            },}
                        }
                        onChange={this.handleModelChangeRu}
                    />
                    
                    <Header as="h5">
                        <span className="modal__title">Titlu Ro: <span style={{color: 'red'}}>*</span></span>
                    </Header>
                    <Input fluid name="titleRo" value={titleRo} onChange={(e) => this.handleUserInput(e)} placeholder='Titlu RO' />
                    <Header as="h5">
                        <span className="modal__title">Descrierea RO:</span>
                    </Header>

                    <Editor
                        apiKey='hze0gfshyw4fs3pird0o18w32momr2m3bc72mk64htp3ro44'
                        initialValue={modelRo}
                        init={{
                            height: 500,
                            menubar: 'file edit view insert format tools table tc help',
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor', 'searchreplace visualblocks code fullscreen', 'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                            ,
                            images_upload_handler: function (blobInfo, success, failure) {
                                var xhr, formData;
                                formData = new FormData();
                                formData.append('image', blobInfo.blob(), blobInfo.filename());
                                xhr = new XMLHttpRequest();
                                xhr.open('POST', 'https://api.imgur.com/3/image');

                                xhr.setRequestHeader('Authorization', 'Client-ID 8d26ccd12712fca');
                                xhr.send(formData);
                                xhr.onload = function() {
                                  var json;
                            
                                  if (xhr.status !== 200) {
                                    failure('HTTP Error: ' + xhr.status);
                                    return;
                                  }
                            
                                  json = JSON.parse(xhr.responseText);
                            
                                  if (!json || typeof json.location !== 'string') {
                                    success(json.data.link);
                                    return ;
                                  }
                            
                                  success(json.location);
                                };
                            },}
                        }
                        onChange={this.handleModelChangeRo}
                    />

                    <div className='panel panel-default'>
                      <FormErrors formErrors={this.state.formErrors} />
                    </div>
                    <Header as="h4">
                        <span className="modal__title">Imagine: <span style={{color: 'red'}}>*</span></span>
                        <div className={`error-image ${!noImage ? 'hidden' : '' }`}>
                            Atentie! Imaginea este obligatorie.
                        </div>
                    </Header>
                    <div className="modal__image" onClick={()=> this.setState({noImage: false})}>
                        <ModalImg catInfoIcon={result.data && result.data.page.cover_img} />
                    </div>

                    <div className="modal__bottom">
                        <Button className="button__green" disabled={!this.state.formValid} floated='right'>Salvează</Button>
                    </div>
                </form>
        )
    }

     addPage = async (e) => {
        const pageId = this.props.match.params.id;
        e.preventDefault()
        const submitData = new FormData(e.currentTarget);
            // console.log(submitData.get('cover_img'))
            // console.log(this.state.cover_img)
        const fileRead = new FileReader(),
            formData = {
            'title.ru': submitData.get('titleRu'),
            'title.ro': submitData.get('titleRo'),
            'description.ru': this.state.modelRu,
            'description.ro': this.state.modelRo,
            'cover_img': submitData.get('cover_img').size > 5 ? await new Promise( (resolve, reject) => {
                fileRead.onloadend = () => resolve(fileRead.result);
                try {
                    fileRead.readAsDataURL(submitData.get('cover_img'))
                }
                catch(e) {
                    reject(console.log(e.message))
                }
            } ) : this.state.cover_img,
            'category_id': parseInt(submitData.get('category_id')),
        };
        if(pageId) formData['_id'] = parseInt(pageId);
        // if (this.state.cover_imgBack && submitData.get('cover_img').size <= 0) {
        //     this.setState({
        //         noImage: true
        //     })
        // }
        const json = JSON.stringify(formData);
            await fetch( URI + '/page/view', { method: 'POST', credentials: "same-origin", body: json } )
            .then( ressult => ressult.json() )
            .then( ({data, errors}) => {
                if (errors.cover_img) {
                    this.setState({
                        noImage: true
                    })
                } else {
                    this.props.history.push('/cabinet/page');
                }
            })
        // this.props.history.push('/cabinet/page');
     }
}
