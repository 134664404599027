import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// import { Redirect } from 'react-router-dom';
import { Checkbox } from 'semantic-ui-react';
// import { AddEditRaport } from '../../graphql/mutation';

import InputsPart from './parts/inputsPart';
import FormMap from './parts/formMap';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0.5em;
`;

const BodyContainer = styled.div`
  display: flex;
  width: 100%;
`;

const InputsContainer = styled.div`
  min-width: 368px;
  margin-right: 16px;
`;

const MapContainer = styled.div`
  width: 100%;
`;

const CheckboxContainer = styled.div`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  .priority-check {
    display: block;
    margin-bottom: 16px;
    color: #283238;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
  }
`;

const SendBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 176px;
  border-radius: 4px;
  background-color: #2db84b;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #fff;
  cursor: pointer;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const ErrorDiv = styled.div`
  margin-top: 20px;
  font-size: 23px;
  font-weight: 500;
  letter-spacing: -0.6px;
  line-height: 32px;
`;

const UnorderedList = styled.ul`
  list-style-type: none;
  text-transform: uppercase;
  color: #7f98ac;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
`;

const ApplyForm = props => {
  const [t, i18n] = useTranslation();
  const [reportID, changeReportID] = useState('');
  const [coordinates, changeCoordinates] = useState(props.coordinates);
  const [address, changeAdressParent] = useState(props.address && props.address);
  const [region, changeRegionParent] = useState(props.region && props.region);
  // const [submitCoordinates, changeSubmitCoordinates] = useState(props.submitCoordinates);
  const [checkedCategoryID, changeCategoryID] = useState('');
  const [currentAdress, changeAdress] = useState('');
  const [currentDescription, changeDescription] = useState('');
  const [media, changeMedia] = useState([]);
  const [anonimApply, changeAnonimApply] = useState(false);
  const [readedRules, changeReadedRules] = useState(false);
  const [sectorData, chengeSectorData] = useState({});
  const [errors, changeErrors] = useState([]);
  useEffect(() => {
      changeCoordinates(coordinates);
      changeAdressParent(address)
      changeRegionParent(region)
  }, [props]);

  useEffect(() => {
    changeErrors([]);
  }, [currentDescription, currentAdress, checkedCategoryID, readedRules]);
  return (
    <Container>
      <BodyContainer>
        <InputsContainer>
          <InputsPart
            t={t}
            currentLang={i18n.language}
            categories={props.categories}
            address={address}
            errors={errors}
            region={props.region}
            checkedCategoryID={checkedCategoryID}
            changeCategoryID={changeCategoryID}
            currentAdress={currentAdress}
            currentRegion={props.region}
            changeAdress={changeAdress}
            changeAdressParent={changeAdressParent}
            coordinates = {coordinates}
            changeSubmitCoordinates={props.changeSubmitCoordinates}
            currentDescription={currentDescription}
            changeDescription={changeDescription}
            changeCoordinates={changeCoordinates}>
          </InputsPart>
        </InputsContainer>
        <MapContainer>
          <FormMap
            t={t}
            coordinates={coordinates}
            address={props.address}
            changeCoordinates={changeCoordinates}
            changeSubmitCoordinates={props.changeSubmitCoordinates}
            changeAdressParent={props.changeAdressParent}
            region={props.region}
            changeRegionParent={props.changeRegionParent}
            changeAdress={changeAdress}
            chengeSectorData={chengeSectorData}
          />
        </MapContainer>
      </BodyContainer>

    </Container>
  )
};

export default ApplyForm;

// export default ApplyForm;
