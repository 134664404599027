import React from 'react';

const Header = props => {
  return (
    <header>
      <div className="header ui container">
        <nav className="header__navigation">
          <li className="header__navigation__logo">
          </li>
          <li className="header__navigation__lang ui dropdown">
          </li>
        </nav>
      </div>
    </header>
  )
};

export default Header;
