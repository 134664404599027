import React from 'react'
import { NavLink } from 'react-router-dom'
import { Table, Icon, Button } from 'semantic-ui-react';
import ConfirmPopup from '../ConfirmPopup';

export default function PointsTemplate( { dataUsers, deleteUser, showUser, editPage, selectedCategory, getDataPopup, addUser } ) {
    const { _id, address, title, sw_active, coordinates } = dataUsers;
    const deleteThisUser = () => deleteUser( _id );
    return (
        <Table.Row key={_id}>
            <Table.Cell width={1}>
                { _id }
            </Table.Cell>
            <Table.Cell width={6}>
                { title && title.ro }
            </Table.Cell>
            <Table.Cell width={6}>
                { address ?
                  <span>{ address }</span>
                  : '---'
                }
                <Button basic color='blue' floated='right'><a target='_blank' rel="noopener noreferrer" href={`https://map.md/ru/point#17.01/${coordinates.lat}/${coordinates.lon}`}>view on map</a></Button>
            </Table.Cell>
            <Table.Cell width={3}>
                <div className='category__edit'>
                    <Icon name={`eye${!sw_active ? ' slash' : ''}`} onClick={ showUser } />
                    <NavLink className='edit__link' to={`/cabinet/points/edit/${_id}`} ><Icon name='pencil' /></NavLink>
                    <ConfirmPopup
                        actionToDo={ deleteThisUser }
                        trigger={
                            <Icon name='delete' />
                        }
                        modal
                        textToTranslate={'locația'}
                        closeOnDocumentClick
                    />
                </div>
            </Table.Cell>
        </Table.Row>
    )
}
