import React from 'react';
import { Container, Grid } from 'semantic-ui-react';

import Header from '../components/header';
import Footer from '../components/footer';

import { URI } from '../config';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import { withCookies } from 'react-cookie';

const redirect = () => {
    return (
        fetch(URI + '/user/mpass/login/url', { method: 'POST' })
            .then(res => res.json())
            .then(result => {
                if (Object.keys(result.errors).length) {
                    return(
                        alert('Wrong data!')
                    )
                }
                return (
                    window.location.href = result.data.url
                )
            })
        )
    }

class Home extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            loader : false
        }
        this.ShowLoad = this.ShowLoad.bind(this)
    }
    render() {
        const { t } = this.props;
        return (
            <>
            <Container>
                <Grid >
                        <div className={`loader ${ !this.state.loader? 'hidden' : 'getFixed'}`}></div>
                        <Header t={t} ShowLoad={this.ShowLoad} />
                        <div className={`wrapper-home ${ this.state.loader && 'unvisible'}`}>
                            <div className="wrapper__home">
                                <img src={logo} style={{maxHeight: '140px'}} alt="logo_id" />
                                <h2 className="wrapper__home__title__bottom">{t('home_title_bold')}</h2>
                                { this.props.cookies.cookies.auth && this.props.cookies.cookies.auth.length > 10 ?
                                    <Link to="/cabinet/category" className="fake-button">{t('entrance_is')}</Link>
                                :
                                <>
                                    <button onClick={redirect}>{t('entrance')}</button>
                                </>
                                }
                            </div>
                            <div className="wrapper__home__about">
                                <h1>
                                    {t('about_service')}
                                </h1>
                                <span>
                                    {t('about_service_desc')}
                                </span>
                            </div>
                        </div>
                    </Grid>
                </Container>
                <Footer />
            </>
        );
    }
    ShowLoad = () => this.setState({ loader : true }, () => setTimeout( () => this.setState({ loader : false }), 600))
}

export default withCookies(Home);
