import React, { useState, useEffect } from 'react'
import {Formik, Form as FormikForm, Field, FieldArray} from 'formik'
import { Header,
         Button,
         Input,
         Form,
         Container } from 'semantic-ui-react';

import { URI } from '../config'
import Questions from './Chestionare/QuestionsNew'
import Head from './Chestionare/head'


const headHr = {
    borderBottom: '1px solid gainsboro',
    paddingBottom: '30px',
    marginBottom: '25px',
    width: '100%',
};

const submitForm = (formProps) => {

};

const changeEditable = (isEditable, changeEdit, setData, setError, pageId, title, newPageId, valuesChestionar, values) => {
    const pageIdChange = pageId || 0;
      changeEdit(!isEditable)
    if(isEditable){
        fetch(URI + `/questionnaire/view?_id=${pageIdChange}`, {method: 'POST', credentials: "same-origin", body: JSON.stringify({_id: parseInt(pageIdChange), title})})
            .then(res => res.json())
            .then(({data, errors}) => {
                setData(data)
                // setFieldValue(`chestionarId`, data._id)
                setError(errors)
            })
            .catch(e => setError(e))
    }
};
const sendQuestion = (questionData, setFieldValue, data, setError, _id, title, answers) => {
        const id = data._id,
              json = questionData,
              questionId = _id || 0;
        if(id) {
            json.questionnaire_id = parseInt(id);
        }
        if (typeof _id === 'number') {
          json.title = title
          json.answer = answers
          json._id = questionId
        }
       fetch( URI + `/question/view?${id ? ('questionnaire_id=' + id) : ''}${questionId ? ('&id=' + questionId) : ''}`, {method: 'POST', credentials: "same-origin", body: JSON.stringify(json)})
        .then(res => res.json())
        .then( (ressult) => {
            let dataQuestion = ressult.data;
            return (
              setFieldValue('newQuestion', {...json, exist: true, id: dataQuestion._id}),
              setFieldValue(`questionId`, dataQuestion._id)
            )
          }
        )
        .catch(e =>
            setError(e)
        )

}

const submitAnswer = (data, question, props, answers, values, id, answerId, _id) => {
    const AnswerIdFromValues = values.answerId || 0;
    const intedAddAnswer = question.answer.map(answerAdd => {
      return {
        title: answerAdd.title,
        points: parseInt(answerAdd.points)
      }
    });
    const questionarId = data._id,
          questionIdFromValues = parseInt(values.questionId) || 0,
          json = {
              'questionnaire_id': parseInt(questionarId),
              'question_id': parseInt(questionIdFromValues),
              'type': parseInt(question.type),
              'value': intedAddAnswer
          };
          // edit answers button
          const intedEditAnswer = values.answers.map(point => {
            return {
              title: point.title,
              points: parseInt(point.points)
            }
          })
          if (values.answerId) {
            json.value = intedEditAnswer
            json._id = parseInt(AnswerIdFromValues)
            json.question_id = parseInt(questionIdFromValues)
          }
          if (answers.length) {
            json.type = values.answerType
          }
          fetch(URI + `/answer/view${questionarId ? ('?questionnaire_id=' + questionarId) : ''}${questionIdFromValues ? ('&question_id=' + questionIdFromValues) : ''}${AnswerIdFromValues ? ('&_id=' + AnswerIdFromValues) : ''}`, {method: 'POST', credentials: "same-origin", body: JSON.stringify(json)})
            .then(props.history.push('/cabinet/chestionare'))
}
let initialValues = {};
const options = [
    { key: 1, text: 'Radiobutton', value: 1 },
    { key: 2, text: 'Checkbox', value: 2 },
]
export default function Chestionarea(props){
    const pageId = props.match.params.id,
          isEditPage = !!pageId,
          [error, setError] = useState({}),
          [data, setData] = useState(null),
          [isEditable, changeEdit] = useState(!pageId),
          [activeQuestion, changeActive] = useState(false),
          [questionArray, changeQuestionArray] = useState([]),
          [questionForm, toggleQuestionForm] = useState(false);
    useEffect(() => {
        if(data || Object.keys(error).length)
            return
        fetch( URI + `/questionnaire/view?id=${pageId || 0}`, {method : 'GET', credentials: "same-origin"})
            .then(res => res.json())
            .then(({data, errors}) => {
                setError(errors)
                setData(data)
            })
            .catch(e => setError(e));
        if (pageId) {
            fetch( URI + `/question/list-view${pageId ? ('?questionnaire_id=' + pageId) : ''}`, {method : 'GET', credentials: "same-origin"})
                .then(res => res.json())
                .then(result => {
                    changeQuestionArray(result.data)
                })
                .catch(e => console.log(e));
        }
    })
    if(Object.keys(error).length)
        return (
            <div>
                {error.message || JSON.stringify(error)}
            </div>
        )
    if(!data)
        return (
            <div className="ui active centered inline loader"> </div>
        )
    initialValues = {
        chestionar_id: data._id,
        chestionarId: '',
        title : {
            'ro': data.title? data.title.ro : '',
            'ru': data.title? data.title.ru : '',
        },
        questions: questionArray,
        questionId: '',
        answers: [],
        answerId: '',
        newQuestion: {
            title: {
              ro: '',
              ru: '',
            },
            id : '',
            exist: false,
            type: data.type ? data.type : 1,
            answer: [
                {
                    title: {
                      ro: '',
                      ru: '',
                    },
                    points: 10,
                }
            ]
        }
    };
    return(
        <Container>
            <Formik
                onSubmit={submitForm}
                initialValues={initialValues}>
            {({values, isSubmitting, setFieldValue, errors}) => {
                const buttonFunction = changeEditable.bind(null, isEditable, changeEdit, setData, setError, data._id, values.title, values.chestionar_id, values),
                      sendQuestionFunction = sendQuestion.bind(null, values.newQuestion, setFieldValue, data, setError),
                      addAnswerFunction = setFieldValue.bind(null, 'newQuestion', {...values.newQuestion, answer: values.newQuestion.answer.concat({title:{},points: 10})}),
                      addAnswerEditFunction = setFieldValue.bind(null, 'answers', {...values.answers, answers: values.answers.concat({title:{}, points: 10})}),
                      handleClickAnswer = submitAnswer.bind(null, data, values.newQuestion, props, values.answers, values)
                return(
                    <FormikForm className='modal_page ui form'>
                        <Head
                            props={props}
                            key={values._id}
                            isEditable={isEditable}
                            buttonFunction={buttonFunction}
                            title={values.title}
                            errors={errors}
                            newPageId={data._id}
                            />

                        {
                            // TO BE CONTINUED...
                        }
                        {
                                <FieldArray
                                   name='questions'
                                   render={ fieldArrayHelpers => (
                                       <>
                                           <Header as='h3' floated='left' style={headHr}>
                                               Întrebările formularului
                                           </Header>

                                           {!isEditable && !questionForm &&
                                               <div className='formular-head'>
                                                   <Button
                                                       color='blue'
                                                       type='button'
                                                       floated='right'
                                                       onClick={
                                                         () => { return (
                                                           toggleQuestionForm(!questionForm),
                                                           changeActive(!activeQuestion))}
                                                       }
                                                    >
                                                           Adaugă întrebare
                                                   </Button>
                                               </div>}
                                           {!!questionArray.length && (
                                           questionArray.map((qn, index) => {
                                               if(!values.questions.some( key => key._id === qn._id)){
                                                    fieldArrayHelpers.insert(values.questions.length - 1, qn)
                                                }
                                                const indexInQuestions = values.questions.findIndex(key => key._id === qn._id)
                                               return (
                                                    <Questions
                                                      key={index}
                                                      props={props}
                                                      pathname={props.location.pathname}
                                                      question={qn}
                                                      isActive={activeQuestion === qn._id}
                                                      changeActive={changeActive}
                                                      activeQuestion={activeQuestion}
                                                      index={indexInQuestions}
                                                      editedIndex={index}
                                                      sendQuestionFunction={sendQuestionFunction}
                                                      options={options}
                                                      isEditPage={isEditPage}
                                                      questionarId={values.chestionar_id}
                                                      setData={setData}
                                                      setFieldValue={setFieldValue}
                                                      addAnswerEditFunction={addAnswerEditFunction}
                                                      handleClickAnswer={handleClickAnswer}
                                                      values={values}
                                                    />
                                                )
                                            }))}
                                            {questionForm &&
                                                <>
                                                    <div className='preform'
                                                         style={{
                                                             marginTop:'100px',
                                                             backgroundColor: 'white',
                                                             padding: '45px',
                                                             border: '1px solid gainsboro',
                                                             borderRadius: '5px'}}>
                                                        <Header as='h3'>
                                                            Adaugă întrebare
                                                        </Header>
                                                        {
                                                            ['ro','ru'].map(lang => (
                                                                <Form.Group widths='equal' key={lang}>
                                                                    <div className='field'>
                                                                        <label>
                                                                            Întrebare {lang.toUpperCase()}: <span className='errors'>*</span>
                                                                        </label>
                                                                        <div className='ui input'>
                                                                            <Field
                                                                                id={`form-input-question-${lang}`}
                                                                                name={`newQuestion.title.${lang}`}
                                                                                control={Input}
                                                                                placeholder={values.title[lang] && `Ce inseamna HIV?`}
                                                                                key={lang}
                                                                                readOnly={values.newQuestion.exist}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Form.Group>
                                                            ))
                                                        }
                                                        <Button
                                                            type='button'
                                                            floated='right'
                                                            disabled={values.newQuestion.title.ro.length > 1 && values.newQuestion.title.ru.length > 1 ? false: true}
                                                            color={!isEditable? 'green' : 'grey'}
                                                            onClick={sendQuestionFunction}>
                                                            {!isEditable ?
                                                                'Salvează și continuă'
                                                            :
                                                                'Editează'}
                                                        </Button>
                                                    </div>
                                                    {values.newQuestion.exist &&
                                                        <div className='preform'
                                                            style={{
                                                                marginTop:'20px',
                                                                backgroundColor: 'white',
                                                                padding: '45px',
                                                                border: '1px solid gainsboro',
                                                                borderRadius: '5px'}}>
                                                            <div style={{marginTop:'20px'}}>
                                                                <Header as='h3'>
                                                                    Adaugă răspuns
                                                                </Header>
                                                                <Form.Group widths='equal' style={{position: 'relative'}}>
                                                                    <span className='errors errors__chestionnare'>*</span>
                                                                    <Form.Select fluid label='Tip' options={options} name='value' onChange={(e, data)=> setFieldValue('newQuestion', {...values.newQuestion, type : data.value})} placeholder='Tip' />
                                                                </Form.Group>
                                                                    {values.newQuestion.answer.map((answer, index) => {
                                                                        return (
                                                                            <>
                                                                                {
                                                                                    ['ro','ru'].map(lang => (
                                                                                        <Form.Group widths='equal' key={lang}>
                                                                                            <div className='field'>
                                                                                                <label>
                                                                                                    Răspuns {lang.toUpperCase()}: <span className='errors'>*</span>
                                                                                                </label>
                                                                                                <div className='ui input'>
                                                                                                    <Field
                                                                                                        id={`form-input-question-answer-${lang}`}
                                                                                                        name={`newQuestion.answer.${index}.title.${lang}`}
                                                                                                        control={Input}
                                                                                                        placeholder={values.title[lang] && `HIV inseamna ...`}
                                                                                                        key={lang}/>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Form.Group>
                                                                                    ))
                                                                                }
                                                                                <Form.Group widths='equal' key={index}>
                                                                                    <div className='field'>
                                                                                        <label>
                                                                                            Punctaj:
                                                                                        </label>
                                                                                        <div className='ui input'>
                                                                                            <Field
                                                                                                type="number"
                                                                                                defaultValue={ '10' }
                                                                                                name={`newQuestion.answer.${index}.points`}
                                                                                                control={Input}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </Form.Group>
                                                                            </>
                                                                    )})}
                                                                <Form.Button className='button__green' onClick={addAnswerFunction} color='blue' floated='left'>Adaugă răspuns</Form.Button>
                                                                <Form.Button
                                                                    className='button__green answer__button'
                                                                    onClick={handleClickAnswer}
                                                                    color='green'
                                                                    floated='right'
                                                                    disabled={values.newQuestion.answer[0].title.ro.length > 1 && values.newQuestion.answer[0].title.ru.length > 1 ? false : true}
                                                                >Salvează și continuă</Form.Button>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </>
                                    )}/>
                        }
                        {

                        }
                    </FormikForm>
            )}}
            </Formik>
        </Container>
    )
}
