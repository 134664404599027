import React from 'react';
import { Table, Icon } from 'semantic-ui-react';
import UsersAppPopup from '../UsersAppPopup';
import ConfirmPopup from '../ConfirmPopup';

export default function PagesTemplate( { dataUsers, deleteUser, showUser, editPage, selectedCategory, getDataPopup, addUser } ) {
    const { _id, login, sw_active } = dataUsers;
    const deleteThisUser = () => deleteUser( _id );
    return (
        <Table.Row>
            <Table.Cell width={1}>
                { _id }
            </Table.Cell>
            <Table.Cell>
                { login }
            </Table.Cell>
            <Table.Cell width={3}>
                <div className='category__edit'>
                    <Icon name={`eye${!sw_active ? ' slash' : ''}`} onClick={ showUser } />
                    <UsersAppPopup
                        trigger = { <Icon name='pencil' /> } addUser={addUser} showUser={showUser} catInfo={ dataUsers }/>
                    <ConfirmPopup
                        actionToDo={ deleteThisUser }
                        trigger={
                            <Icon name='delete' />
                        }
                        modal
                        textToTranslate={'utilizatorul aplicației'}
                        closeOnDocumentClick
                    />
                </div>
            </Table.Cell>
        </Table.Row>
    )
}
