import * as React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



class RedirectsFilter extends React.Component {
	constructor(props){
		super(props);
		this.state={
			region: '',
			subdivision: '',
			badge_number:'',
			date_from: '',
			date_to: '',
		}
	}
	handleChange = e =>{
		this.setState({[e.target.name]:e.target.value})
	}
	setStartDate = date =>{
		this.setState({
			date_from: new Date(date).getTime()
		});
	}
	setEndDate = date =>{
		this.setState({
			date_to: new Date(date).getTime()
		});
	}

	handleSubmit = event =>{
		event.preventDefault();

		const data = {
			region:this.state.region,
			subdivision:this.state.subdivision,
			badge_number:this.state.badge_number,
			date_from:this.state.date_from ? this.state.date_from/1000 : '',
			date_to:this.state.date_to ? this.state.date_to/1000 : ''
		}
		this.props.onChangeData(1, data);
	}

	resetForm = e =>{
		this.props.onChangeData(1);
		this.setState({
			date_from: '',
			date_to: '',
			region: '',
			subdivision: '',
			badge_number:''
		});
	}


	render() {
		return (
		<div className="filter-redirect">
			<form noValidate onSubmit={this.handleSubmit} onReset={this.resetForm}>
				<p className="filter-redirect-title">Filtrează:</p>
				<div className="filter-redirect-row">
					<div className="filter-redirect-column">
						<label className="filter-redirect-label" htmlFor="redirect-region">
							Regiune
						</label>
						<input
							className="form-input"
							type="text"
							name="region"
							id="redirect-region"
							onChange={this.handleChange}
						/>
					</div>
					<div className="filter-redirect-column">
							<label className="filter-redirect-label" htmlFor="redirect-sub">
								Subdiviziuni
							</label>
							<input
							className="form-input"
							type="text"
							name="subdivision"
							id="redirect-sub"
							onChange={this.handleChange}
							/>
					</div>
					<div className="filter-redirect-column">
							<label className="filter-redirect-label" htmlFor="redirect-badge">
								Numărul legitimației
							</label>
							<input
							className="form-input"
							type="text"
							name="badge_number"
							id="redirect-badge"
							onChange={this.handleChange}
							/>
					</div>
				</div>
				<div className="filter-redirect-row">
					<div className="filter-redirect-column">
						<label className="filter-redirect-label" htmlFor="redirect-startdate">
							Start Date
						</label>
						<DatePicker
							selected={this.state.date_from}
							onChange={this.setStartDate}
							startDate={this.state.date_from}
							endDate={this.state.date_to}
							maxDate = {new Date()}
							id="redirect-startdate"
						/>
					</div>
					<div className="filter-redirect-column">
						<label className="filter-redirect-label" htmlFor="redirect-enddate">
							End Date
						</label>
						<DatePicker
							selected={this.state.date_to}
							onChange={this.setEndDate}
							startDate={this.state.date_from}
							endDate={this.state.date_to}
							minDate={this.state.date_from}
							maxDate = {new Date()}
							id="redirect-enddate"
						/>
					</div>
					<div className="filter-redirect-buttons">
						<input type="submit" value="OK" className="filter-redirect-submit" />
						<input type="reset" value="Reset" className="filter-redirect-reset" />
					</div>
				</div>
			</form>
		</div>
		)
}
}

export default RedirectsFilter
