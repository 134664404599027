import React from 'react';
import Popup from "reactjs-popup";
import {
    Container,
    Header,
    Button,
    Icon,
    Input
} from 'semantic-ui-react';
import useForm from "react-hook-form";

export default ( { trigger, addUser, showCategory, catInfo }, props ) => {
    const { handleSubmit, register, errors } = useForm(),
          onSubmit = (data, e, close) => {  addUser(data, e, close) };
    return (
        <Popup  modal
                closeOnDocumentClick
                lockScroll
                trigger={
                    trigger
                }
                position="right center">
                    { close => (
                        <Container textAlign='left'>
                            <form
                                onSubmit={
                                    handleSubmit((data, e) => onSubmit(data, e, close))
                                }
                                className="modal">
                                <span className="close" onClick={close}>
                                    <Icon name="close" />
                                </span>
                                <Header>
                                    <input className='hidden' name='category_id' defaultValue={ catInfo && catInfo._id}
                                      ref={register({})}
                                      />
                                    Adaugă email
                                </Header>
                                <Header className="modal__top" dividing>
                                    { catInfo &&
                                    <div className="modal__top__title">
                                        <span>ID utilizator: </span>
                                            <span> {catInfo._id} </span>
                                        </div>
                                    }
                                </Header>
                                <Header as="h5">
                                    <span className="modal__title">Email:</span>
                                </Header>
                                <Input fluid defaultValue={ catInfo ? catInfo.email : '' } name="email" placeholder={ catInfo ? catInfo.email : 'test@mail.ru' }
                                    ref={register({
                                      required: 'Required',
                                      pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "invalid email address"
                                      }
                                    })}
                                    />
                                <div className='errors'>{errors.email && errors.email.message}</div>
                                <div className="modal__bottom">
                                    <Button className="button__green" floated='right'>Salvează</Button>
                                </div>
                            </form>
                        </Container>
                )}
        </Popup>
    )
}
