import React from 'react';
import { NavLink } from 'react-router-dom';
import { Table, Icon } from 'semantic-ui-react';
import { Draggable } from "react-beautiful-dnd";
import ConfirmPopup from '../ConfirmPopup';

export default function PagesTemplate({ showChestionar, deleteChestionar, index, dataChestionar }) {
    const { _id, title, sw_active, total_passed, total_passed_avg } = dataChestionar,
          showThisChestionr = () => showChestionar( _id, sw_active ),
          deleteThisChestionar = () => deleteChestionar( _id )
    return (
        <Draggable
          key={index}
          draggableId={dataChestionar._id}
          index={index}
        >
          {(provided, snapshot) => (
              <tr
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <Table.Cell width={1}>
                  { _id }
                </Table.Cell>
                <Table.Cell>
                  { title && title.ro }
                </Table.Cell>
                <Table.Cell width={1}>
                  { total_passed && total_passed }
                </Table.Cell>
                <Table.Cell width={2}>
                  { total_passed_avg && total_passed_avg }
                </Table.Cell>
                <Table.Cell width={3}>
                  <div className='category__edit'>
                    <Icon name={`eye${!sw_active ? ' slash' : ''}`} onClick={showThisChestionr} />
                    <NavLink className='edit__link' to={`/cabinet/chestionare/edit/${_id}`} >
                      <Icon name='pencil' />
                    </NavLink>
                    <ConfirmPopup
                        actionToDo={ deleteThisChestionar }
                        trigger={
                            <Icon name='delete' />
                        }
                        modal
                        textToTranslate={'chestionarul'}
                        closeOnDocumentClick
                    />
                  </div>
                </Table.Cell>
              </tr>
          )}
        </Draggable>
    )
}
