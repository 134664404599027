import React from 'react';
import { Table, Icon } from 'semantic-ui-react';
import Moment from 'react-moment';
import RedirectsPopup from '../RedirectsPopup';

export default function RedirectItemTemplate( { dataIndex, dataRedirect } ) {
    const { date, name_surname, badge_number, subdivision } = dataRedirect;
    return (
        <Table.Row>
            <Table.Cell width={1}>
                {dataIndex}
            </Table.Cell>
            <Table.Cell>
                <Moment unix format="DD.MM.YYYY">{date}</Moment>
            </Table.Cell>
            <Table.Cell>
                { name_surname }
            </Table.Cell>
            <Table.Cell>
                { badge_number }
            </Table.Cell>
            <Table.Cell>
                { subdivision }
            </Table.Cell>
            <Table.Cell width={1}>
                <RedirectsPopup
                    trigger = { <Icon name='eye' /> }
                    dataRedirect={ dataRedirect}
                />
            </Table.Cell>
        </Table.Row>
    )
}
