import React from 'react';
import EmailPopup from './EmailPopup';
import EmailTemplate from './templates/EmailTemplate';
import { Table,
         Container,
         Header,
         Button } from 'semantic-ui-react';
import { URI } from '../config';

export default class Email extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            ready : false,
            result : []
        };
    }
    updatePages(){
      const page = 1
      fetch( URI + `/common-emails/list-view?page=${page}`, { method : 'GET', credentials: "same-origin" } )
        .then(res => res.json())
        .then( ({data, errors}) => this.setState({
            ready : true,
            result : {
                data,
                errors
            }
        }))
        .catch(e => this.setState({ ready : true, result : { errors : {error : e.message } }}));
    }
    componentDidMount() {
        this.updatePages()
    }
    render() {
        const addButton = <Button floated='right' className="category__add">Adaugă email</Button>
        const { ready, result } = this.state
        if(!ready)
            return (
                <div className="ui active centered inline loader"></div>
            )
        if( result &&  Object.keys(result.errors).length )
            return(
                <div>
                    500 - Something went wrong
                </div>
            )
        return (
            <>
                <Container>
                    <Header as='h2' floated='left'>Raport despre traume</Header>
                    <Button floated='right' className="raport__create" onClick={this.raportClick}>Raport despre traume</Button>
                    <EmailPopup
                        trigger = { addButton }
                        addUser = { this.addUser }
                         />
                </Container>
                <Table celled padded centered={"true"}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Email:</Table.HeaderCell>
                            <Table.HeaderCell>Editare:</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        { result.data && result.data.map( (dataUsers, index) => {
                            let showUserFunction = this.showUser.bind( this, dataUsers._id, dataUsers.sw_active, index )
                            let deleteUserFunction = this.deleteUser.bind(this, dataUsers._id, index)
                            return <EmailTemplate key={ dataUsers._id } showUser={showUserFunction} deleteUser={deleteUserFunction} addUser={this.addUser}  dataUsers={ dataUsers } />
                        }) }
                    </Table.Body>
                </Table>
            </>
        )
    }
    showUser = ( id, sw_active, index ) => fetch( URI + '/common-emails/view/', { method: 'PUT', credentials: "same-origin", body: JSON.stringify( { id, action : sw_active ? 'hide' : 'show' } )  } )
                                      .then( ressult => ressult.json() )
                                      .then( json => this.setState(prevState => {
                                              let newData = [ ...prevState.result.data ]
                                              newData.splice(index, 1, json.data)
                                                  return { result: {...prevState.result, data: newData} }
                                          })
                                      )
    deleteUser = ( id, index ) => fetch( URI + '/common-emails/view/', { method: 'DELETE', credentials: "same-origin", body: JSON.stringify( { id } ) } )
                                     .then( ressult => ressult.json() )
                                     .then( json => this.setState(prevState => {
                                            let newData = [ ...prevState.result.data ]
                                            newData.splice(index, 1)
                                                return { result: {...prevState.result, data: newData} }
                                         })
                                     )
    addUser = async (e,data,close) => {
      const pageId = e.category_id || 0
      data.preventDefault();
      const submitData = new FormData(data.target);
      const json = JSON.stringify({
          '_id': parseInt(pageId),
          email: submitData.get('email'),
      });
      await fetch( URI + `/common-emails/view/`, { method: 'POST', credentials: "same-origin", body: json } );
      this.setState(prevState => this.updatePages())
      close()
    }
    raportClick = () => {
        window.location.href = '/cabinet/page/edit/234'
    }
}
