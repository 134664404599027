import React  from 'react';
import { Header,
         Icon,
         Input,
         Form,
         Container,
         Dropdown
       } from 'semantic-ui-react';

import { URI } from '../config';

import ReactMapGl, { Marker, MapController, NavigationControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl';
import SimpleReactValidator from 'simple-react-validator';
import FormErrors from './Validate/FormErrors';
import PointsMap from '../components/ApplyForm';

// MAP.MD AUTHORIZATION

const API_KEY = '3b58ae56-1981-4195-a527-0f436f6d3e0e';
const ENCODE = Buffer.from(`${API_KEY}:xxx`).toString('base64');
const AUTHORIZATION = `Basic ${ENCODE}`;
const REQUEST_OPTIONS = {
  headers: {
    Authorization: AUTHORIZATION,
  }
};

export default class PointsAdd extends React.Component {
    constructor(props){
        super(props);
        this.events = ['click'];
        this.state = {
            ready : false,
            result : null,
            content: "",
            mapStyle: null,
            title: true,
            mapMarker: false,
            categoryId: [1],
            checked: false,
            timesClicked: 0,
                // viewport: {
                //     latitude: 47.023006,
                //     longitude: 28.833677,
                //     zoom: 12
                // },
            coordinates: {
                lng: 28.840094966238524,
                lat: 47.02348495743132,
            },
            address: '',
            region: '',
            titleRu: '',
            titleRo: '',
            formErrors: {titleRu: '', titleRo: ''},
            titleRuValid: false,
            titleRoValid: false,
            formValid: false,
        };
        this.addPage = this.addPage.bind(this);
        this.validator = new SimpleReactValidator();

    }

    handleEvent(event) {
      if (event.type === 'click') {
        // console.log('hi');
      }
      return super.handleEvent(event);
    }

    // handler changes of MapBoxReact
    _onViewportChange = (viewport) => {
      this.setState({viewport});
    }

    // HandleMarkerClick = (marker, prevState) => {
    // this.setState((prevState) => {
    //   return {timesClicked: prevState.timesClicked + 1}
    // });
        // if (this.state.timesClicked >= 2) {
        //   this.setState(
        //     {
        //       marker: {
        //         latitude: marker.lngLat[1],
        //         longitude: marker.lngLat[0]
        //       }
        //     }
        //   )
        // }
  // }

    componentDidMount() {
        // GET styles from mapApi for MapboxGl React
        fetch('https://map.md/api/tiles/styles/map/style.json', REQUEST_OPTIONS)
              .then(resp=>resp.json())
              .then(json=>this.setState({mapStyle: json}))

        // SET id to page is opened or new
        const pageId = this.props.match.params.id;

        // GET info of page (with ID or without)
        fetch( URI + `/map-point/view?id=${pageId ? pageId : 0}`, { method : 'GET', credentials: "same-origin" } )
            .then(res => res.json())
            .then( ({data, errors}) => {
                if (data && data.title && data.address) {
                    this.setState({
                        titleRu: data.title.ru,
                        titleRo: data.title.ro,
                        formValid: true,
                        titleRuValid: true,
                        titleRoValid: true,
                    })
                }
                if (data && data.coordinates){
                    this.setState({
                        coordinates: {
                            lng: data.coordinates.lon,
                            lat: data.coordinates.lat,
                        }
                    })
                }
                this.setState({
                    ready : true,
                    checked : data.type,
                    categoryId : data.category_id,
                    result : {
                        data,
                        errors
                    }
                });
        })
        .catch(e => this.setState({ ready : true, result : { errors : {error : e.message } }}));
    }

    // handler changes of 'Categorie locatiei'
    handleOnChangeCategory = (e, { value }) => {
      this.setState({
        categoryId: value
      })
    }

    changeSubmitCoordinates = (coordinatesSubmit) => {
        this.setState({
             coordinates: coordinatesSubmit,
         })
    }
    changeAdressParent = (addressSubmit) => {
        this.setState({
             address: addressSubmit,
         })
    }
    changeRegionParent = (regionSubmit) => {
        this.setState({
             region: regionSubmit,
         })
    }


    handleUserInput(e) {
      const name = e.target.name,
            value = e.target.value;
      this.setState({[name]: value}, () => {
          this.validateField(name, value)
      });
    }

    validateField(fieldName, value) {
      let fieldValidationErrors = this.state.formErrors;
      let titleRuValid = this.state.titleRuValid;
      let titleRoValid = this.state.titleRoValid;
      switch(fieldName) {
        case 'titleRu':
          titleRuValid = value.length >= 5;
          fieldValidationErrors.titleRu = titleRuValid ? '' : ' este scurt';
          break;
        case 'titleRo':
          titleRoValid = value.length >= 5;
          fieldValidationErrors.titleRo = titleRoValid ? '': ' este scurt';
          break;
        default:
          break;
      }
      this.setState({formErrors: fieldValidationErrors,
                      titleRuValid: titleRuValid,
                      titleRoValid: titleRoValid,
                    }, this.validateForm);
    }

    validateForm() {
      this.setState({formValid: this.state.titleRuValid && this.state.titleRoValid});
    }

    // handleMarkers = (e) => (
    //   this.setState({
    //     mapMarker: true,
    //   })
    // )

    errorClass(error) {
       return(error.length === 0 ? '' : 'has-error');
    }

    render() {
        const optionss = [
          { key: 'a', text: 'Persoanele cu HIV', value: '1' },
          { key: 'b', text: 'Bolnavii de TB', value: '2' },
          { key: 'c', text: 'Hepatita și altele', value: '3' },
          { key: 'v', text: 'Consumatorii de droguri', value: '4' },
          { key: 'e', text: 'Bărbați care practică sex cu bărbații', value: '5' },
          { key: 't', text: 'Lucrătorii sexului comercial', value: '6' },
        ]
        const { ready, result, mapStyle, titleRu, titleRo } = this.state;
        if(!ready)
            return (
                <div className="ui active centered inline loader"> </div>
            );
        if( result &&  Object.keys(result.errors).length )
            return(
                <div>
                    500 - Something went wrong
                </div>
            );
        const filteeredCategoriesId = result.data.category_id && result.data.category_id.map(el => (
          el.toString()
        ))
        return (
            <Container>
                <Header as='h3'>
                    Adaugă o locație pe hartă
                </Header>
                <Form onSubmit={ this.addPage } className='modal_page'>

                    <Form.Group widths='equal'>
                        <Form.Input
                            required
                            id='form-input-control-organization'
                            name='titleRu'
                            defaultValue={result.data.title ? result.data.title.ru : titleRu}
                            onChange={(e) => this.handleUserInput(e)}
                            control={Input}
                            fluid
                            label='Denumirea Organizației Ru:'
                            placeholder='Комисариат н.12'
                        />
                        <Form.Input
                            required
                            id='form-input-control-organization-ro'
                            name='titleRo'
                            defaultValue={result.data.title ? result.data.title.ro : titleRo}
                            onChange={(e) => this.handleUserInput(e)}
                            control={Input}
                            fluid
                            label='Denumirea Organizației Ro:'
                            placeholder='Comisariatul nr.12'
                        />
                    </Form.Group>
                    <div className='panel panel-default'>
                      <FormErrors formErrors={this.state.formErrors} />
                    </div>

                    <Form.Group>
                        <Form.Input
                          defaultValue={ result.data.working_hours ? result.data.working_hours : '' }
                          name='working_hours'
                          id='form-input-control-time'
                          control={Input}
                          width={6}
                          fluid
                          label='Orar de lucru'
                          placeholder={ result.data.working_hours ? result.data.working_hours : 'Lun-Vin 8:00 - 16:00' }
                        />
                        <Form.Input defaultValue={ result.data.phones ? result.data.phones[0] : '' } name='phone' id='form-input-control-phone' control={Input} width={5} label='Telefon 1' placeholder='+373 69 89 87 72' />
                        <Form.Input defaultValue={ result.data.phones ? result.data.phones[1] : '' } name='phone-mobile' id='form-input-control-mobile-phone' control={Input} width={5} label='Telefon 2' placeholder='+373 69 89 87 22' />
                    </Form.Group>

                    <Form.Group>
                        <Form.Input
                          required
                          defaultValue={ result.data.email ? result.data.email : '' }
                          name='email'
                          id='form-input-control-email'
                          control={Input}
                          width={8}
                          label='E-mail'
                          placeholder='ozo.straseni@gmail.com'
                        />
                        <Form.Input
                          // required
                          defaultValue={ result.data.webpage ? result.data.webpage : '' }
                          name='webpage'
                          id='form-input-control-website'
                          control={Input}
                          width={8}
                          label='Webpage adress:'
                          placeholder='www.mail.ru'
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Field
                          required
                          name='category_id'
                          id='form-select-control-capital'
                          control={Dropdown}
                          fluid
                          multiple
                          selection
                          width={13}
                          onChange={this.handleOnChangeCategory}
                          label='Categorie locație:'
                          options={optionss}
                          placeholder='СПИД'
                          defaultValue={filteeredCategoriesId}
                        />
                        <div className='three wide field'>
                            <label>Tipul locație</label>
                            <Form.Checkbox label='ONG' defaultChecked={result.data.type ? result.data.type : false} onClick={(e) => this.setState(prevState => ({checked: !prevState.checked}))} />
                         </div>
                    </Form.Group>
                    <Form.Group className="mapPoints">
                        <PointsMap
                          categories={optionss}
                          coordinates={this.state.coordinates}
                          changeSubmitCoordinates={this.changeSubmitCoordinates}
                          address={this.state.address}
                          region={this.state.region}
                          changeAdressParent={this.changeAdressParent}
                          changeRegionParent={this.changeRegionParent}
                        />
                    </Form.Group>
                    <Form.Button
                        color='green'
                        disabled={!this.state.formValid || !this.state.address}
                        className='button__green'
                        floated='right'
                    >
                        Salvează
                    </Form.Button>
                </Form>
            </Container>
        )
    }

     addPage = async (e) => {
         const pageId = this.props.match.params.id;
         e.preventDefault()

         if (this.state.title === true) {
            // console.log('You submitted the form and stuff!');
          } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
          }
         const categoryArray = this.state.categoryId
         const normalizedCategory = categoryArray.map(item => parseInt(item))
         const submitData = new FormData(e.currentTarget),
               formData = {
                 'type': this.state.checked,
                 'working_hours': submitData.get('working_hours'),
                 'title.ru': submitData.get('titleRu'),
                 'title.ro': submitData.get('titleRo'),
                 'email': submitData.get('email'),
                 'webpage': submitData.get('webpage'),
                 'phones': [
                     submitData.get('phone'),
                     submitData.get('phone-mobile')
                 ],
                 'address': this.state.address,
                 'region': this.state.region,
                 'coordinates': {
                     lat: this.state.coordinates.lat,
                     lon: this.state.coordinates.lng
                 },
                 'category_id': normalizedCategory,
                 // 'lng': submitData.get()
             };
         if(pageId) formData['_id'] = parseInt(pageId);
         const json = JSON.stringify(formData);
         await fetch( URI + '/map-point/view', { method: 'POST', credentials: "same-origin", body: json } );
         this.props.history.push('/cabinet/points/');
     }
}
