import React from 'react';
import { Header, Form } from 'semantic-ui-react';
import { Field } from 'formik';

const blockSaveFormular = {
    height: '40px',
};
const headHr = {
    borderBottom: '1px solid gainsboro',
    paddingBottom: '30px',
    marginTop: '40px',
};
export default ({isEditable, buttonFunction, title, newPageId, errors, props}) => {
  return (
    <>
        <Header as='h3' style={headHr}>
            Crează un fomular
        </Header>
        <Form.Group widths='equal' key={newPageId}>
            {['ro','ru'].map(lang => (
                    <div className='field' key={lang}>
                        <label>
                            Numele formularului {lang.toUpperCase()}: <span className='errors'>*</span>
                        </label>
                        <div className='ui input'>
                            <Field
                                width={8}
                                id={`form-input-control-organization-${lang}`}
                                key={lang}
                                readOnly={!isEditable}
                                placeholder={title[lang]}
                                name={`title.${lang}`}
                                disabled={!isEditable}
                                validate={true}
                             />
                        </div>
                    </div>
            ))}
        </Form.Group>
        <input type='hidden' name='new_id' value={newPageId ? newPageId : ''} />
        <div style={blockSaveFormular}>
            <Form.Button
                color={isEditable? 'green' : 'grey'}
                floated='right'
                type='button'
                disabled={title.ru.length > 1 && title.ro.length > 1 ? false : true}
                onClick={buttonFunction}>
                {isEditable ? 'Salvează' : 'Editează'}
            </Form.Button>
        </div>
    </>
  )
}
